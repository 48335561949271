import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { InputField } from "../../InputField/InputField";
import { SelectFieldProps } from "../SelectField";
import { countriesList } from "./countriesList";

export function CountryField(props: SelectFieldProps<string>) {
    return (
        <Autocomplete
            id="CountriesField-autocomplete"
            options={countriesList}
            onChange={(event, value) => props.input.onChange(value)}
            autoHighlight
            getOptionLabel={(option) => option}
            defaultValue={countriesList.find((option) => option === props.input.value)}
            renderInput={(params) =>
                <InputField
                    disableMemo={true}
                    //@ts-ignore
                    ref={params.InputProps.ref}
                    {...params}
                    {...props}
                    inputProps={params.inputProps}
                />}
        />
    );
}
