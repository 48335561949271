import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Location } from "~/components/containers/LocationFormTemplate/types";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HomeIcon from "@material-ui/icons/Home";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { useStatus, statusConstants } from "~/features/status";

function a11yProps(index: any) {
  return {
    key: `vertical-tab-${index}`,
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    height: 600,
    overflow: "scroll",
    "& flexContainerVertical": {
      minWidth: "100%",
      alignItems: "center",

    }
  },
  button: {
    width: "100%",
    minWidth: "100%",
    textAlign: "left",
    height: "50px",
    borderRight: `1px solid ${theme.palette.divider}`,
    boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 6px 0px rgb(0 0 0 / 12%)",
    "& span": {
      flexDirection: "row",
      width: "200px",
      justifyContent: "flex-start",
      alignItems: "center",
      display: "block",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    },
    "& svg": {
      marginRight: 8,
      marginBottom: 3,
      color: `${theme.palette.primary}`
    }
  },
  tab: {
    width: "100%",
    minWidth: "100%",
    textAlign: "left",
    "& span": {
      flexDirection: "row",
      width: "200px",
      justifyContent: "flex-start",
      alignItems: "center",
      display: "flex",
      overflow: "hidden",
    },
    "& svg": {
      marginRight: 8,
      marginBottom: "auto !important",
      color: `${theme.palette.primary}`
    }
  },
}));

interface Props {
  records: Location[];
  handleAddRecord: () => void;
  selected: number | null;
  handleSelectRecord: (index: number) => void;
  disableButton: boolean;
  passedValidation: boolean;
}

export function VerticalTabs(props: Props) {
  const {
    records,
    handleAddRecord,
    selected,
    handleSelectRecord,
    passedValidation,
  } = props;
  const classes = useStyles();
  const { isLoading, status, message } = useStatus();
  const lastRecordNotSaved = !records[records.length - 1].id
  return (
    <div className={classes.root}>
      <Tab
        disableFocusRipple={false}
        className={classes.button}
        label="Add new"
        icon={<AddCircleIcon />}
        onClick={handleAddRecord}
        disabled={lastRecordNotSaved || isLoading || Boolean(status === statusConstants.ACTIVE && message === statusConstants.PLEASE_SAVE_MESSAGE) || !passedValidation}
      />
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={selected}
        aria-label="location-vertical-tab"
        className={classes.tabs}
        scrollButtons="off"
      >
        {records.map((record: Location, index: number) =>
          record ? (
            <Tab
              className={classes.tab}
              label={record.name}
              hidden={Boolean(record.id)}
              value={index}
              style={{ backgroundColor: selected === index ? "#f1f8e9" : "#fff" }}
              icon={index === 0 ? <HomeIcon /> : <LocationOnIcon />}
              onClick={() => passedValidation && selected !== index ? handleSelectRecord(index) : {}}
              disabled={Boolean(selected !== index && status === statusConstants.ACTIVE) || Boolean(!passedValidation || isLoading)}
              {...a11yProps(index)}
            />
          ) : (
            ""
          )
        )}
      </Tabs>
    </div>
  );
}
