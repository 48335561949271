import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import { useStatus, statusConstants } from "~/features/status";

interface Props {
  activeStep: number;
  handleSetActiveStep: (num: number) => void;
  locationExists?: boolean;
  passedValidation: boolean;
}

export function LocationTabs(props: Props) {
  const {
    activeStep,
    handleSetActiveStep,
    locationExists = true,
    passedValidation,
  } = props;

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    handleSetActiveStep(newValue);
  };

  const handleNext = (val: number) => {
    handleSetActiveStep(val);
  };

  const { isLoading, status } = useStatus(); 

  const generateProps = (_value: number) => {
    return {
      value: _value,
      onClick: () => passedValidation && Boolean(locationExists) ? handleNext(_value) : {},
      disabled: Boolean((activeStep !== _value && (!passedValidation || status === statusConstants.ACTIVE)) || isLoading || !Boolean(locationExists)),
      style: { backgroundColor: activeStep === _value ? "#e1f5fe" : "#fff"}
    };
  };

  return (
    <AppBar position="static" elevation={1}>
      <Tabs
        value={activeStep}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        color="default"
        style={{
          background: "#fff",
        }}
      >
        <Tab label="Basic" {...generateProps(0)} />
          <Tab label="Contact" {...generateProps(1)} />
          <Tab label="Product/Pricing"  {...generateProps(2)} />
          <Tab label="Offers"  {...generateProps(3)} />
      </Tabs>
    </AppBar>
  );
}
