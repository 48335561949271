import React from "react";
import { Paper, Grid } from "@material-ui/core";
import { steps, LocationFormProps } from "./types";
import { VerticalTabs } from "~/components/library/CreateRecordBar/VerticalTabs";
import { FormTemplate } from "./FormTemplate";
import {
  useSubmitHandler,
  useRecords
} from "./hooks";

export const LocationFormTemplate = (props: LocationFormProps) => {
  const {
    formRef,
    passedValidation,
    handleFormPassesValidation,
    resource,
  } = props;
  const {
    records,
    activeStep,
    handleSetActiveStep,
    currRecord, setCurrRecord,
    selected, setSelected, destroyAddNew,
    exposeValues,
    createEmpty,
    setRecords
  } = useRecords(props);
  const activeRecordType = steps[activeStep];

  const handleSelectRecord = (index: number) => {
    setCurrRecord(records[index]);
    handleSetActiveStep(0);
    setSelected(index);
    return;
  };

  const {
    handleAddRecord,
    handleSubmit,
    noRecordsPassesValidation,
  } = useSubmitHandler(activeRecordType, resource, records, currRecord, createEmpty, setRecords, handleSelectRecord)

  const formProps = {
    selected: selected,
    formRef: formRef,
    onSubmit: handleSubmit,
    exposeValues: exposeValues,
    step: activeStep,
    recordType: activeRecordType,
    records,
    passedValidation,
    handleFormPassesValidation,
    destroyAddNew,
    resource,
    handleSelectRecord
  };

  return (
    <Paper square elevation={0} variant="outlined">
      <Grid container spacing={0}>
        <Grid item xs={2}>
          <VerticalTabs
            disableButton={
              !(noRecordsPassesValidation) ||
              Boolean(currRecord?.id) || !passedValidation
            }
            records={records}
            handleAddRecord={handleAddRecord}
            selected={selected}
            handleSelectRecord={handleSelectRecord}
            passedValidation={passedValidation}
          />
        </Grid>
        <FormTemplate
          currRecord={currRecord}
          selected={selected}
          formProps={formProps}
          handleSetActiveStep={handleSetActiveStep}
          activeStep={activeStep}
        />
      </Grid>
    </Paper>
  );
};
