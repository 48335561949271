import {ReactNode} from "react";
import {relayStylePagination} from "@apollo/client/utilities";
import {ApolloClient, ApolloProvider, createHttpLink, from, InMemoryCache} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {useGetToken} from "~/features/authorization/useGetToken";

interface ApolloProps {
    children?: ReactNode
}

function Apollo({children}: ApolloProps) {

    const getTokenAsync = useGetToken();
    
    const tokenLink = setContext(async (_, {headers}) => {
        const token = await getTokenAsync();
        return {
            headers: {
                ...headers,
                "Authorization": token ? `Bearer ${token}` : null
            }
        }
    })

    const httpLink = createHttpLink({
        uri: import.meta.env.VITE_API_URL
    });

    const apolloClient = new ApolloClient({
        name: 'Atlas',
        version: '1.0',
        link: from([tokenLink, httpLink]),
        cache: new InMemoryCache({
            typePolicies: {
                Query: {
                    fields: {
                        resources: relayStylePagination(),
                    },
                },
            }
        }),
        // headers: {
        //   "Content-Type": "application/json",
        //   "Access-Control-Allow-Origin": "*",
        //   "Access-Control-Allow-Credentials": true,
        // },
    });

    return (
        <ApolloProvider client={apolloClient}>
            {children}
        </ApolloProvider>
    )
}

export default Apollo;
