
import React, { useState } from "react";
import { Field } from "react-final-form";
import {
  NestedFieldArray
} from "./NestedFieldArray";
import { FIELD_ARRAY } from "~/components/forms/form";
import { RemoveCardButton } from "./RemoveCardButton"
import { IDField } from "~/components/library/InputField";
import { Grid } from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const FieldArrayAccordion = ({
  record,
  i,
  searchTerm,
  fields,
  recordConstants
}: {
  record: any,
  i: number,
  searchTerm: string,
  fields: any,
  recordConstants: any
}) => {
  const [expanded, setExpanded] = useState(!fields.value[i].id);
  const display = !searchTerm ? "block" : Object.values(fields.value[i]).join(",").includes(searchTerm) ? "block" : "none";
  const handleRemoveCard = () => {
    if (fields.value[i].id) {
      if (fields.value[i].shouldDelete) {
        const {
          shouldDelete,
          ...rest
        } = fields.value[i];
        fields.update(i, {
          ...rest
        })
      } else {
        fields.update(i, {
          ...fields.value[i],
          shouldDelete: true
        })
      }
    } else fields.remove(i)
  }
  return (
    <Grid item xs={12} key={`${i}-${record}-fa`} style={{ display }}>
      <Accordion
        expanded={expanded}
        style={{ backgroundColor: "#f8f8f8", opacity: fields.value[i].shouldDelete ? "0.4" : 1 }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          onClick={() => setExpanded(!expanded)}
          aria-controls={`${record}-action${i}-content`}
          id={`${record}-actions${i}-header`}
          style={{ display: "flex", alignItems: "flex-start", margin: 0, paddingTop: 8 }}
          IconButtonProps={{
            size: "small",
            style: {
              marginTop: 8,
            }
          }}
        >

          <Grid container spacing={2}>
            <Grid item xs={expanded ? 9 : 3} key={`${recordConstants.name}-card-id`}>
              {/* @ts-ignore */}
              {fields.value[i]?.id ? <Field name="id" label="Id" InputProps={{ readOnly: true }} fullWidth component={IDField} /> : ""}
            </Grid>
            {expanded ? "" : <Grid item xs={6}>
              <Field {...recordConstants.fields[0]} InputProps={{ readOnly: true }} name={`${record}.name`} />
            </Grid>}
            <Grid item xs={3} style={{ display: "flex", justifyContent: "flex-end" }}>
              <RemoveCardButton deleting={fields.value[i].shouldDelete} handleOnClick={handleRemoveCard} />
            </Grid>
          </Grid>

        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {recordConstants.fields.map((field: any, index: number) => {
              if (field.type === FIELD_ARRAY) {
                return (
                  <NestedFieldArray
                    field={field}
                    index={index}
                    name={`${record}.${field.name}`}
                    key={`${index}-nested-field-array`}
                    effectiveOn={fields.value[i].effectiveOn}
                    expiredOn={fields.value[i].expiredOn}
                  />
                );
              } else {
                return (
                  <Grid
                    item
                    key={`${index}-card-field`}
                    {...field.wrapperProps}
                  >
                    <Field {...field} name={`${record}.${field.name}`} inputColor="#fff" />
                  </Grid>
                );
              }
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
