import React, {useState, useEffect} from 'react';

type StorageType = 'local' | 'session';

interface UseStorageProps { 
    type: StorageType,
    key: string,
    defaultValue: any
}

export function useStorage({ type, key, defaultValue }: UseStorageProps) {
    const [value, setValue] = useState(() => getStorageValue({ type, key, defaultValue }));

    useEffect(() => {
        if (value === undefined) return;
        
        getStorage(type)?.setItem(key, JSON.stringify(value))
    }, [key, value]);
    
    const handleClearStorageValue = () => {
        getStorage(type)?.removeItem(key);
    }
    
    return [value, setValue, { clearValue: handleClearStorageValue }] as const;
}

export function useLocalStorage(key: string, defaultValue: any) {
    return useStorage({ type: 'local', key, defaultValue });
}

export function useSessionStorage(key: string, defaultValue: any) {
    return useStorage({ type: 'session', key, defaultValue });
}

export default useStorage;


function getStorageValue({ type, key, defaultValue }: UseStorageProps) {
    const cachedValue = getStorage(type)?.getItem(key);
    if (!cachedValue) return defaultValue;
    
    return JSON.parse(cachedValue) ?? defaultValue;
}

function getStorage(type: StorageType): Storage | undefined {
    const storageType = getStorageType(type);
    return window[storageType];
}

function getStorageType(type: StorageType)  {
    const storageType = type === 'local'
        ? 'localStorage'
        : type === 'session'
            ? 'sessionStorage'
            : undefined;
    
    if (!storageType) throw new Error(`Unsupported storage type ${type}`);
    
    return storageType;
}
