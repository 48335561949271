import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import {StatusFilter, type StatusFilterOptions} from "~/components/containers/ResourceTable/StatusFilter";
import GlobalFilter from "~/components/library/ReactTable/GlobalFilter";
import {Button} from "~/components/library/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import PostAddIcon from "@material-ui/icons/PostAddSharp";
import EmailIcon from "@material-ui/icons/EmailRounded";
import React from "react";

interface ResourceDataGridToolbarProps {
    totalSelectedRows: Number,
    selectedStatus: StatusFilterOptions,
    onStatusSelection: (value: StatusFilterOptions) => void,
    onAddRecord: () => void,
    onClearSelection: () => void,
    onExport: () => void,
    isCreatingReport: boolean
}

const ResourceDataGridToolbar = ({
    totalSelectedRows,
    selectedStatus,
    onStatusSelection,
    onAddRecord,
    onClearSelection,
    onExport,
    isCreatingReport
}: ResourceDataGridToolbarProps) => {
    return (
        <Grid container className="pb-2">
            <Grid item xs={12} md={5}>
                {totalSelectedRows === 0 ? (
                    <>
                        <Typography variant="h6" className="text-lg font-medium">
                            Synapse Resource Management
                        </Typography>
                        <Typography variant="body1" color="primary">
                            Dashboard View
                        </Typography>
                    </>
                ) : (
                    <div className="flex items-center">
                        <div>
                            <Typography variant="h6" className="text-lg font-medium">
                                Synapse Resource Management
                            </Typography>
                            <Typography variant="body1" color="primary">
                                {totalSelectedRows > 1
                                    ? `Selected ${totalSelectedRows} rows for export`
                                    : `Selected 1 row export`} &nbsp;
                                <span className="underline cursor-pointer" onClick={onClearSelection}>
                            (Clear Selection)
                        </span>
                            </Typography>
                        </div>
                    </div>
                )}
            </Grid>
            <Grid container item xs={12} md={7}>
                <Grid item xs={12} sm={3} md={3} className="flex items-center pr-4">
                    <StatusFilter value={selectedStatus} onSelection={onStatusSelection}/>
                </Grid>
                <Grid item xs={12} sm={5} md={6} className="flex items-center pr-4">
                    <GlobalFilter/>
                </Grid>
                <Grid item xs={12} sm={4} md={3} className="ml-2" style={{height: "100%"}}>
                    <Button fullWidth
                            disableElevation
                            className="h-[56px]"
                            disabled={isCreatingReport}
                            color={totalSelectedRows === 0 ? 'primary' : 'secondary'}
                            endIcon={isCreatingReport
                                ? <CircularProgress size={18}/>
                                : totalSelectedRows === 0
                                    ? <PostAddIcon/>
                                    : <EmailIcon/>}
                            onClick={totalSelectedRows === 0 ? onAddRecord : onExport}>
                        {totalSelectedRows === 0 ? "Add Record" : "Export"}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ResourceDataGridToolbar;
