import {type TableOperation, ResourceTable} from "~/components/library/Table/ResourceTable";
import type {
    CreateReportMutation,
    CreateReportMutationVariables,
    Resource as GqlQueryResource
} from "~/__generated__/graphql";
import columns from "~/components/library/ResourceDataGrid/ResourceDataGridColumns";
import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {Resource} from "~/components/forms/ResourceForm";
import {useNavigate} from "react-router";

import {type StatusFilterOptions, ALL} from "~/components/containers/ResourceTable/StatusFilter";
import {PaginationState, type Table, type TableState} from "@tanstack/react-table";
import CircularProgress from "@material-ui/core/CircularProgress";
import Control from "~/components/library/ReactTable/Control";
import {usePersistingTableState} from "~/features/persistingTableState";
import {useSearch} from "~/features/search";
import {useMutation} from "@apollo/client";
import {mutations} from "~/features/graphql";
import ResourceDataGridToolbar from "~/components/library/ResourceDataGrid/ResourceDataGridToolbar";
import {useLocalStorage} from "~/hooks/useStorage";


const isEmpty = (value: any) => value && value.constructor === Object && Object.values(value).length === 0;

const getRowId = (originalRow: GqlQueryResource) => originalRow.id;

function getStatusFilterValue(table: Table<GqlQueryResource>): StatusFilterOptions {
    const state = table.getState();
    return (state.columnFilters.find(x => x.id === 'expiredOn')?.value ?? ALL) as StatusFilterOptions;
}

function LoadingDataGrid() {
    return (
        <div className="w-full flex align-center justify-center overflow-hidden py-6">
            <CircularProgress/>
        </div>
    )
}

export default function ResourceDataGrid() {

    const navigate = useNavigate();
    const {setFilterByClause, setOrderByClause} = usePersistingTableState();

    const {searchResources, data, loading} = useSearch();
    const [createReport, {loading: loadingCreateReport}] =
        useMutation<CreateReportMutation, CreateReportMutationVariables>(mutations.reports.createReport)

    const [tableColumns, setTableColumns] = useState(columns);
    const [tableRows, setTableRows] = useState<GqlQueryResource[]>([]);

    const [savedPaginationSize, setSavedPaginationSize] = useLocalStorage('resourcePageSize', 10);

    // useEffect(() => {
    //     searchResources();
    // }, []);

    const handleDeleteRow = (id: string) => {

    }

    const handleViewMediaObject = () => {

    }

    const handleAddRecord = () => {
        navigate('/resource', {
            state: {
                record: undefined
            }
        });
    };

    const handleEditRecord = (record: Resource) => {
        navigate(`/resource/${record.id}`, {
            state: {
                id: record.id,
                record: record
            }
        });
    };

    React.useMemo(() => {
        setTableColumns([...tableColumns, {
            id: "control",
            header: () => <div style={{width: "100px"}}></div>,
            cell: ({row}) => (
                <div>
                    <Control
                        row={row}
                        onEdit={handleEditRecord}
                        // onDelete={handleDeleteRow}
                        // viewMediaObjects={handleViewMediaObject}
                    />
                </div>
            ),
            enableSorting: false
        }]);
    }, [columns]);

    React.useMemo(() => {
        const nodes = data?.resources?.edges?.map(edge => edge.node) ?? [];
        setTableRows(nodes as GqlQueryResource[]);
    }, [data]);

    const handleStatusFilterSelection = (table: Table<GqlQueryResource>) => (value: StatusFilterOptions) => {
        const state = table.getState();
        const column = table.getAllColumns()
            .find(x => x.columnDef.id === 'expiredOn')

        setFilterByClause({id: 'expiredOn', value})
        column?.setFilterValue(value);
    }

    const handleExport = (tableState: TableState) => () => {
        const resourceIds = Object.keys(tableState.rowSelection);
        if (resourceIds.length > 0) {
            createReport({
                variables: {
                    resourceIds
                }
            })
        }
    }

    const handleTableStateChange = (payload: TableOperation) => {
        console.log(payload.operation, payload.state);
        if (payload.operation === 'state' || payload.operation === 'pagination') {
            const paginationState = payload.operation === 'state' 
                ? (payload.state as TableState).pagination 
                : (payload.state as PaginationState)
            
            setSavedPaginationSize(paginationState.pageSize);
            
            const tableState = (payload.state as TableState);
            const orderBy = tableState.sorting[0];
            setOrderByClause(orderBy);
        }
    }

    // if (loading) return <LoadingDataGrid />

    return (
        <div className="pt-1">
            <ResourceTable columns={tableColumns} rows={tableRows} getRowId={getRowId}
                           initialState={{pagination: {pageSize: savedPaginationSize}}}
                           toolbarSlot={(table) => {
                               const tableState = table.getState();
                               return (
                                   <Grid item xs={12} style={{paddingBottom: 0}}>
                                       <ResourceDataGridToolbar
                                           totalSelectedRows={Object.values(tableState.rowSelection).length ?? 0}
                                           selectedStatus={getStatusFilterValue(table)}
                                           onStatusSelection={handleStatusFilterSelection(table)}
                                           onAddRecord={handleAddRecord}
                                           onClearSelection={() => table.resetRowSelection()}
                                           onExport={handleExport(tableState)}
                                           isCreatingReport={loadingCreateReport}
                                       />
                                   </Grid>
                               );
                           }} 
                           onStateChange={handleTableStateChange}/>
        </div>
    )
}
