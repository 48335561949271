import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { useStatus } from "~/features/status";
import { Collapse } from '@material-ui/core';

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    paddingRight: theme.spacing(5),
    borderLeftStyle: 'solid',
    borderLeftWidth: 5,
  },
  idle: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    borderColor: theme.palette.primary.main,
    '& a': {
      color: theme.palette.primary.dark,
    },
  },
  // active: {
  //   backgroundColor: theme.palette.primary.light,
  //   color: theme.palette.primary.dark,
  //   borderColor: theme.palette.primary.main,
  //   '& a': {
  //     color: theme.palette.primary.dark,
  //   },
  // },
  success: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
    borderColor: theme.palette.secondary.main,
    '& a': {
      color: theme.palette.secondary.main,
    },
  },
  active: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
    borderColor: theme.palette.warning.main,
    '& a': {
      color: theme.palette.warning.dark,
    },
  },
  // active: {
  //   backgroundColor: theme.palette.error.light,
  //   color: theme.palette.error.dark,
  //   borderColor: theme.palette.error.main,
  //   '& a': {
  //     color: theme.palette.error.dark,
  //   },
  // },
  button: {
    position: 'absolute',
    background: 'transparent',
    color: 'inherit',
    border: 'transparent',
    top: 15,
    right: 5,
    cursor: 'pointer',
  },
});

const StatusMessage = ({
  classes,
  children
}) => {
  const { status, message } = useStatus();
  return (
    <Collapse in={Boolean(message)}>
    <div
      className={classNames({
        [classes.root]: classes.root,
        [classes[status]]: true,
      })}
    >
      {children}
    </div>
    </Collapse>
  );
}

export default withStyles(styles)(StatusMessage);
