import React, { useContext, createContext } from "react";

export interface SortBy {
    id: string;
    desc: boolean;
}

export interface FilterBy {
    id: string,
    value: string
}

export interface Pagination {
    pageIndex: number,
    pageSize: number
}

export interface PersistingTableState {
    pageIndex: number;
    pageSize: number;
    sortBy: SortBy[];
    filterBy: FilterBy[]
}

export type TableState = {
    tableState: PersistingTableState;
    setOrderByClause: (orderBy: SortBy) => void,
    setFilterByClause: (filterBy: FilterBy) => void,
    setPagination: (pagination: Pagination) => void,
    handleTableStateUpdate: (tableState: PersistingTableState) => void
}

const TableStateContext = createContext<TableState>({
    tableState: {
        pageIndex: 0,
        pageSize: 100,
        sortBy: [],
        filterBy: [],
    }, 
    handleTableStateUpdate: () => { },
    setOrderByClause: () => {},
    setFilterByClause: () => {},
    setPagination: () => {},
});

TableStateContext.displayName = "TableStateContext";

const usePersistingTableState = () => useContext(TableStateContext)

const PersistingTableStateProvider = ({ children }: { children: React.ReactNode }) => {
    const [tableState, setTableState] = React.useState<PersistingTableState>({
        pageIndex: 0,
        pageSize: 100,
        sortBy: [],
        filterBy: []
    });

    const handleSetSetOrderClause = (sortBy: SortBy) => {
        setTableState(state => ({
            ...state,
            sortBy: [ sortBy ]
        }));
    }
    
    const handleSetFilterClause = (filterBy: FilterBy) => {
        setTableState(state => ({
            ...state,
            filterBy: [ filterBy ]
        }));
    }
    
    const handleSetPagination = ({ pageIndex, pageSize }: Pagination) => {
        setTableState(state => ({
            ...state,
            pageSize,
            pageIndex
        }));
    }
    
    const handleTableStateUpdate = (_tableState: PersistingTableState) => {
        setTableState(_tableState)
    };

    return (
        <TableStateContext.Provider
            value={{
                handleTableStateUpdate,
                setOrderByClause: handleSetSetOrderClause,
                setFilterByClause: handleSetFilterClause,
                setPagination: handleSetPagination,
                tableState
            }}>
            {children}
        </TableStateContext.Provider>
    )
};

export { usePersistingTableState, PersistingTableStateProvider };
