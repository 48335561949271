import {
    createLocation
} from "./createLocation";
import {
    updateLocation
} from "./updateLocation";
import {
    deleteLocation
} from "./deleteLocation";

const locations = {
    createLocation,
    updateLocation,
    deleteLocation
}
export {
    locations
}