import { useMutation } from "@apollo/client";
import {
    mutations, query
} from "~/features/graphql";
import { constants } from "../types";
import { Resource } from "~/components/forms/ResourceForm/types"
import { Location, Price } from "~/components/forms/LocationForm/types"
import { useStatus, statusConstants } from "~/features/status";

const {
    BASIC,
    CONTACTS,
    PRODUCTS,
    OFFERS,
} = constants;
function clean(obj: any) {
    for (var propName in obj) {
        if (propName === "prices" && obj[propName].length) {
            obj[propName] = obj[propName].filter((price: Price, i: number) => price && !price.shouldDelete)
        }
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
            delete obj[propName];
        }
    }
    return obj
}
const submitNestedForm = async (
    values: Location,
    type: keyof Location,
    indexToSubmit: number[] | undefined,
    updateHandler: any,
    createHandler: any,
    requiredIds: any,
    createPrice?: any
) => {
    if (!indexToSubmit?.length) return;
    const records: any[] = values[type];
    const a: any[] = [];

    await indexToSubmit.reduce(async (promise, index) => {
        // This line will wait for the last async function to finish.
        // The first iteration uses an already resolved Promise
        // so, it will immediately continue.
        await promise;
        let variables = {
            ...clean(records[index]),
            ...requiredIds
        };
        if (type === PRODUCTS && variables.prices?.length) {
            if (variables?.id) {
                await variables.prices.forEach(async (item: Price) => {
                    if (!item.id) {
                        await createPrice(clean({ variables: { ...item, effectiveOn: item.effectiveOn ? item.effectiveOn : variables.effectiveOn, unitPrice: parseFloat(item.unitPrice), productId: variables.id } }))
                    }
                })

                const _prices = variables.prices.filter((item: Price) => item?.id).map((item: Price) => {
                    const {
                        __typename,
                        ...rest
                    } = item;
                    return {
                        ...rest,
                        unitPrice: parseFloat(rest.unitPrice)
                    };
                });
                variables = {
                    ...variables,
                    prices: _prices
                };
            };
        }
        //@ts-ignore
        const submitHandler = variables?.id ? updateHandler : createHandler;
        const { data } = await submitHandler({ variables });
        a.push(data)
    }, Promise.resolve());
    return a;
}

export const useSubmitLocation = (activeRecordType: string, resource: Resource) => {
    const refetchQuery = resource.id ? {
        refetchQueries: [{
            query: query.resource.GET_RESOURCE,
            variables: { id: resource.id }
        }],
        awaitRefetchQueries: true,
    } : {}

    const [createLocation] = useMutation(mutations.locations.createLocation, refetchQuery);
    const [updateLocation] = useMutation(mutations.locations.updateLocation, refetchQuery);

    const [createContact] = useMutation(mutations.contacts.createContact, refetchQuery);
    const [updateContact] = useMutation(mutations.contacts.updateContact, refetchQuery);

    const [createOffer] = useMutation(mutations.offers.createOffer, refetchQuery);
    const [updateOffer] = useMutation(mutations.offers.updateOffer, refetchQuery);

    const [createProduct] = useMutation(mutations.products.createProduct, refetchQuery);
    const [updateProduct] = useMutation(mutations.products.updateProduct, refetchQuery);
    const [createPrice] = useMutation(mutations.prices.createPrice);

    const { setStatus } = useStatus();


    const onSubmit = async (values: Location | null, indexToSubmit?: number[]) => {
        if (!values) return;
        if (!resource.id) return;

        const resourceId = resource.id
        let response;
        setStatus(statusConstants.SUBMITTING);
        switch (activeRecordType) {
            case BASIC: {
                if (values?.id) {
                    response = await updateLocation({
                        variables: {
                            ...values,
                            resourceId,
                            expiredOn: values.expiredOn || null,
                            id: values.id
                        }
                    });
                } else {
                    response = await createLocation({
                        variables: {
                            ...values,
                            expiredOn: values.expiredOn || null,
                            resourceId,
                        }
                    });
                }
                break;
            }
            case CONTACTS: {
                const requiredIds = {
                    locationId: values.id

                }
                response = await submitNestedForm(
                    values,
                    CONTACTS as keyof Location,
                    indexToSubmit,
                    updateContact,
                    createContact,
                    requiredIds
                )
                break;
            }
            case PRODUCTS: {
                const requiredIds = {
                    locationId: values.id,
                    resourceId
                };
                response = await submitNestedForm(
                    values,
                    PRODUCTS as keyof Location,
                    indexToSubmit,
                    updateProduct,
                    createProduct,
                    requiredIds,
                    createPrice
                )
                break;
            }
            case OFFERS: {
                const requiredIds = {
                    locationId: values.id,
                }
                response = await submitNestedForm(
                    values,
                    OFFERS as keyof Location,
                    indexToSubmit,
                    updateOffer,
                    createOffer,
                    requiredIds
                )
                break
            }
            default:
                return;
        };

        setStatus(statusConstants.SUCCESS, "Record Saved");
        return response;
    };

    return onSubmit;
}