import { useParams } from "react-router";
import { CircularProgress, Container } from "@material-ui/core";
import {useLazyQuery} from "@apollo/client";

import { ResourceFormTemplate } from "../../containers/ResourceFormTemplate";
import { BasePage } from "../BasePage";
import { query } from "~/features/graphql"
import { parseDate, getCurrentDay } from "~/components/forms/utils"
import { useEffect } from "react";
import { File, Resource } from "~/__generated__/graphql";

const DEFAULT_COMPANY_ID = '2da6b4d5-1ca6-4b0a-9696-09f50d99ada4';

export interface ResourceExtended extends Partial<Resource> {
  logoDescription: string,
  document: File
}

const prepareFormData = (data: any) => {
  const _effectiveOn = data?.effectiveOn || getCurrentDay();
  const file = data?.files.find((item: any) => item.category === "Resource.Logo") || null;

  return {
    ...data,
    companyId: DEFAULT_COMPANY_ID,
    file,
    logoDescription: file ? file.description ? file.description : data && data.name ? `${data.name} logo` : "" : "",
    document: data?.files.find((item: any) => item.category === "Resource") || null,
    effectiveOn: parseDate(_effectiveOn),
    expiredOn: data?.expiredOn ? parseDate(data?.expiredOn) : "",
    providerTags: Array.isArray(data?.providerTags) ? data?.providerTags : [],
    tags: Array.isArray(data?.tags) ? data?.tags : []
  }
}

export const loader = () => {
  console.log('Resource page is loading!');
}

const LoadingComponent = () => (
  <Container style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "60vh" }}>
    <CircularProgress />
  </Container>
)

type ManageResourceParams = {
  resourceId: string
}

const ManageResourcePage = () => {
  const { resourceId } = useParams<ManageResourceParams>();

  const [fetchResource, { loading, data }] = useLazyQuery(query.resource.GET_RESOURCE, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only"
  });
  
  useEffect(() => {
    if (!resourceId) return;
    async function fetchResourceById() {
      const x = await fetchResource({
        variables: {
          id: resourceId
        }
      });
    }

    fetchResourceById()
        .catch(console.error);
    
  }, [resourceId])

  return (
    <BasePage>
      {loading && !data?.resourceById
        ? <LoadingComponent /> 
        : <ResourceFormTemplate 
              resource={prepareFormData(data?.resourceById)} 
              // resource={data?.resourceById as Resource} 
              setCurrentId={(xid) => {
                console.log('form submitted and successfully handled?', xid)
              }}
          />
      }
    </BasePage>
  );
};

export default ManageResourcePage;
