import {
    createOffer,
} from "./createOffer";

import {
    updateOffer
} from "./updateOffer"

import {
    deleteOffer
} from "./deleteOffer"

const offers = {
    createOffer,
    updateOffer,
    deleteOffer
}

export {
    offers
}