import { gql } from "graphql-tag";

const updateProduct = gql`
    mutation updateProduct(
            $resourceId: UUID!
            $locationId: UUID!
            $id: UUID!
            $name: String!,
            $effectiveOn: DateTime!,
            $expiredOn: DateTime,
            $prices: [InnerUpdatePriceInput!]

    ) {
        updateProduct(
            input: {
                id: $id
                locationId: $locationId
               resourceId: $resourceId
                name: $name
                description: "test"
                effectiveOn: $effectiveOn
                expiredOn: $expiredOn
                prices: $prices
            }
        ) {
        product 
            {
                id
                description
                effectiveOn
                expiredOn
                prices {
                    id
                    description
                    unit
                    unitPrice
                    effectiveOn
                    expiredOn
                }
            }
        }
    }
`;

export {
    updateProduct
}