import { InputField } from "../../library/InputField/InputField";
import { SelectField } from "../../library/SelectField/SelectField";
import { TagsField } from "../../library/SelectField/TagsField";
import { FileInputField } from "../../library/FileInputField"
import { required, effectiveOnLessThanExpiry, expiryAfterEffectiveOn, imageValidator, composeValidators, maxLength } from "../validation";
import { FieldProp } from "../form";
import { TextareaAutosize } from "@material-ui/core";
import { parseDate, normalizeDate } from "../utils";

const maxLength3000 = maxLength(3000, '{{length}}/{{max}}');
const resourceTypeOptions = [

    {
        value: "Device /Product",
    },
    {
        value: "Document",
    },
    {
        value: "Group / Club",
    },
    {
        value: "Helpline",
    },
    {
        value: "Mobile App",
    },
    {
        value: "Non-profit Org",
    },
    {
        value: "Organization",
    },
    {
        value: "Podcast",
    },
    {
        value: "Publication",
    },
    {
        value: "Service /Treatment",
    },
    {
        value: "Video",
    },
    {
        value: "Website",
    },
]

const fields: Array<FieldProp> = [
    {
        name: "name",
        label: "Resource Name",
        validate: required,
        required: true,
        component: InputField,
        fullWidth: true,
        // item: true,
        wrapperProps: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 6
        }
    },
    {
        name: "file",
        label: "Logo file upload (380w x180h)",
        component: FileInputField,
        accept: "image/*",
        validate: imageValidator,
        fullWidth: true,
        wrapperProps: {
            xs: 12,
            sm: 12,
            md: 6
        }
    },
    {
        name: "type",
        label: "Resource Type",
        component: SelectField,
        validate: required,
        options: resourceTypeOptions,
        required: true,
        fullWidth: true,
        // item: true,
        wrapperProps: {
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6
        }
    },
    {
        name: "logoDescription",
        label: "Logo Alt Text / Description",
        component: InputField,
        fullWidth: true,
        wrapperProps: {
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6
        }
    },
    {
        name: "effectiveOn",
        label: "Effective",
        validate: composeValidators(effectiveOnLessThanExpiry, required),
        component: InputField,
        fullWidth: true,
        required: true,
        // item: true,
        type: "date",
        parse: (value) => normalizeDate(value),
        format: (value) => parseDate(value),
        wrapperProps: {
            xs: 6,
            sm: 6,
            md: 6,
            lg: 6
        }
    },
    {
        name: "expiredOn",
        label: "End",
        validate: expiryAfterEffectiveOn,
        component: InputField,
        parse: (value) => normalizeDate(value),
        format: (value) => parseDate(value),
        fullWidth: true,
        type: "date",
        wrapperProps: {
            xs: 6,
            sm: 6,
            md: 6,
            lg: 6
        }
    },
    {
        name: "description",
        label: "Resource Description - 3000 character max",
        component: InputField,
        validate: composeValidators(required, maxLength3000),
        fullWidth: true,
        required: true,
        // item: true,
        multiline: true,
        wrapperProps: {
            xs: 12,
        }
    },
    {
        name: "tags",
        label: "Resource Keywords - Internal",
        validate: required,
        component: TagsField,
        options: [],
        fullWidth: true,
        required: true,
        wrapperProps: {
            xs: 12,
        }
    },
    {
        name: "providerTags",
        label: "Resource Keywords - Partner-provided",
        component: TagsField,
        options: [],
        fullWidth: true,
        wrapperProps: {
            xs: 12,
        }
    },
    {
        name: "companyId",
        label: "Affiliated Company",
        component: InputField,
        initialValue: "2da6b4d5-1ca6-4b0a-9696-09f50d99ada4",
        InputProps: {
            readOnly: true
        },
        fullWidth: true,
        wrapperProps: {
            xs: 12,
            sm: 12,
            md: 6,
        }
    },
    {
        name: "document",
        label: "Document Resource - file upload",
        helperText: "If the resource is a file (e.g. an article in a Word or PDF document), use this field to associate the file.",
        component: FileInputField,
        fullWidth: true,
        wrapperProps: {
            xs: 12,
            sm: 12,
            md: 6
        }
    },
];

export { fields };