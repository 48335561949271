import React, { useEffect, useMemo } from "react";
import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import Grid from "@material-ui/core/Grid";
import { FormProps } from "../form";
import { CheckFieldForm } from "./CheckFieldForm";
import { fields } from "./fields";
import { Resource } from "./types";
import { SpyComponent } from "../SpyComponent";
import { fieldArrayRequired } from "../validation";
import { IDField } from "~/components/library/InputField";
import { useLazyQuery } from "@apollo/client";
import { query } from "~/features/graphql";

export const ResourceForm = (props: FormProps<Resource>) => {
  const { formRef, initialValues, onSubmit, id, ...rest } = props;
  
  const [getResourceByName, { data }] = useLazyQuery(query.resource.GET_RESOURCE_BY_NAME)
  const [name, setName] = React.useState("");

  const _handleSubmit = async (values: Resource, _form: any) => {
    const formState = _form.getState();
    const res = await onSubmit(values, formState.initialValues.id && !formState.dirty);
    _form.reset(res);
  }

  const nameValidate = (value: string) => {
    if (value !== name) setName(value);

    if (!value || !value?.length) 
      return "Required";

    if (data?.resourceByName && data?.resourceByName.id !== initialValues?.id) 
      return "Resource name already exists"

    return undefined
  };

  useEffect(() => {
    if (!name) return;
    const timeOutId = setTimeout(() => getResourceByName({
      fetchPolicy: 'cache-first',
      variables: {
        name
      }
    }), 500);
    return () => clearTimeout(timeOutId);
  }, [name, getResourceByName]);

  const categoryIds = initialValues?.categories?.length ? initialValues.categories.map((cat) => cat.id) : [];

  const memoIntiialValues = useMemo(() => initialValues, [initialValues]);

  return (
    <Form
      onSubmit={_handleSubmit}
      initialValues={memoIntiialValues}
      initialValuesEqual={(a, b) => {         
        return JSON.stringify(a) === JSON.stringify(b) 
      }}
      keepDirtyOnReinitialize={true}
      // initialValuesEqual={(a, b) => {
      //   // return true;
      //   // return status !== statusConstants.SUCCESS; // there is no need to reinitialize resource form value 
      //   return JSON.stringify(a) === JSON.stringify(b)
      // }}
      mutators={{
        ...arrayMutators,
        changeLogoDescription: (args, state, utils) => {
          // @ts-ignore
          const logoDescription = state.formState.values?.logoDescription;
          if (args[0]) {
            if (logoDescription) return;
            utils.changeValue(state, 'logoDescription', () => name ? `${name.trim()} logo` : "")
          } else utils.changeValue(state, 'logoDescription', () => "")
        },
      }}
      render={(props) => {
        const { handleSubmit, form } = props;
        return (
          <form onSubmit={handleSubmit} ref={formRef}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6}>
                <Grid container spacing={2}>
                  {initialValues?.id ? <Grid item key="id" xs={12}>
                    {/* @ts-ignore */}
                    <Field name="id" label="Id" InputProps={{ readOnly: true }} fullWidth component={IDField} />
                  </Grid> : ""}
                  {fields.map((field, index) => {

                    if (field.name === "name") {
                      return <Grid item key={field.name} {...field.wrapperProps}>
                        <Field
                          {...field}
                          validate={nameValidate}
                        />
                      </Grid>
                    };

                    if (field.name === "file") {
                      return <Grid item key={field.name} {...field.wrapperProps}>
                        <Field
                          {...field}
                          onChange={(value: boolean) => form.mutators.changeLogoDescription(value)}
                        />
                      </Grid>
                    }

                    if (field.name === "logoDescription") {
                      return <Grid item key={field.name} {...field.wrapperProps}>
                        <Field
                          {...field}
                        />
                      </Grid>
                    }

                    if (field.name === 'tags') {
                      return (
                        <Grid item key={field.name} {...field.wrapperProps}>
                        <Field
                          {...field}
                          options={initialValues?.tags ?? []}
                        />
                      </Grid>
                      )
                    }

                    if (field.name === 'providerTags') {
                      return (
                        <Grid item key={field.name} {...field.wrapperProps}>
                        <Field
                          {...field}
                          options={initialValues?.providerTags ?? []}
                        />
                      </Grid>
                      )
                    }

                    return (
                      <Grid item key={field.name} {...field.wrapperProps}>
                        <Field
                          {...field}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FieldArray name="categoryIds" validate={fieldArrayRequired} component={CheckFieldForm} initialValue={categoryIds} />
              </Grid>
              <SpyComponent {...rest} />
            </Grid>
          </form>
        );
      }}
    />
  );
};
