import React, {ReactElement} from "react";
import {Routes, Route} from 'react-router-dom';

// components
import ManagePage from "./manage";
import ResourcesPage from "./resources";
import LandingPage from "./landingPage";
import OAuthPage from "~/components/pages/oauth";

const RenderRoutes = (): ReactElement => {
    return (
        <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/oauth2" element={<OAuthPage />} />
            <Route path="/resources" element={<ResourcesPage />} />
            <Route path="/resource/:resourceId?" element={<ManagePage />} />
        </Routes>
    );
};

export default RenderRoutes;
