import React from 'react';
import { IPublicClientApplication } from "@azure/msal-browser";
import { UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "~/features/authorization/authConfig";

interface ButtonProps {
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

interface MsalProps {
    instance: IPublicClientApplication
}

const PanelContainer: React.FC = ({ children }) => {
    return (
        <div className="container lg:w-1/3 sm:w-1/2 shadow-md p-7 mx-auto my-auto bg-white">
            {children}
        </div>
    )
}

const PanelHeader: React.FC = ({ children }) => {
    return (
        <h1 className="font-medium font-bold-300 text-2xl mb-2">{children}</h1>
    )
}

const PanelBody: React.FC = ({ children }) => {
    return (
        <div className="text-gray-800">{children}</div>
    )
}

const PanelFooter: React.FC = ({ children }) => {
    return <div className="pt-5">{children}</div>;
}

const SignInButton: React.FC<ButtonProps> = ({ onClick }) => {
    return (
        <button
            className="
                bg-blue-900 hover:bg-blue-800 
                bg-gradient-to-br from-blue-500 to-blue-700
                focus:outline-none focus:ring-2 focus:ring-offset-2 ring-blue-500 
                text-white font-bold 
                px-7 py-2"
            onClick={onClick}>Sign In</button>
    )
}

function UnauthorizedPanel({ instance }: MsalProps) {
    return (
        <PanelContainer>
            <PanelHeader>Admin</PanelHeader>
            <PanelBody>
                <u className="pr-1">Only authorized employees may use this application.</u>
                If you need to use this application and do not have access, please contact support.
            </PanelBody>
            <PanelFooter>
                <SignInButton onClick={() => instance.loginRedirect(loginRequest)} />
            </PanelFooter>
        </PanelContainer>
    );
}

function AuthorizationPage() {
    const { instance } = useMsal();
    return (
        <UnauthenticatedTemplate>
            <div className="flex min-h-screen bg-gray-100 bg-gradient-to-br from-gray-50 to-gray-200 items-center justify-center">
                <UnauthorizedPanel instance={instance} />
            </div>
        </UnauthenticatedTemplate >
    )
}

export default AuthorizationPage;