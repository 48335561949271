import { Resource } from "~/components/forms/ResourceForm/types"
import { useSubmitLocation } from "./useSubmitLocation";
import { Location } from "../types";
import { useStatus, statusConstants } from "~/features/status";

const useSubmitHandler = (
    activeRecordType: string,
    resource: Resource,
    records: Location[],
    currRecord: Location | null,
    createEmpty: (length: number) => Location,
    setRecords: (records: Location[]) => void,
    handleSelectRecord: (index: number) => void
) => {
    const { setStatus } = useStatus();

    const noRecordsPassesValidation =
        !Boolean(records.length) && currRecord && currRecord.name;

    const onSubmit = useSubmitLocation(activeRecordType, resource);
    const handleSubmit = async (values: Location, indexesToSubmit: number[]) => {
        await onSubmit(values, indexesToSubmit)
    };

    const handleAddRecord = async () => {
        const length = records.length;
            setRecords([...records.filter((location: Location) => !location.deletedOn), createEmpty(length)]);
            handleSelectRecord(length);
            setStatus(statusConstants.ACTIVE);
    };

    return {
        handleAddRecord,
        handleSubmit,
        noRecordsPassesValidation,
    }
}

export {
    useSubmitHandler
}