import {InputField, FinalFormFieldProps} from "./InputField";
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';

export const IDField = (props: FinalFormFieldProps) => {
    const handleButtonClick = () => {
        copyToClipboard(props.input.value)
    }

    function copyToClipboard(text: string) {
        
        // Only for Internet Explorer. We don't support IE, but this code might be useful?
        // @ts-ignore
        if (window.clipboardData && window.clipboardData.setData) {
            // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
            // @ts-ignore
            return window.clipboardData.setData("Text", text);
    
        }
        else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            const textarea = document.createElement("textarea");
            textarea.textContent = text;
            textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
            document.body.appendChild(textarea);
            textarea.select();
            try {
                return document.execCommand("copy");  // Security exception may be thrown by some browsers.
            }
            catch (ex) {
                console.warn("Copy to clipboard failed.", ex);
                return false;
            }
            finally {
                document.body.removeChild(textarea);
            }
        }
    }

    return (
        <div style={{ maxWidth: 300 }}><InputField {...props}  InputProps={{
            readOnly: true,
            endAdornment: (
                <Tooltip title="Copy to clipboard">
                    <InputAdornment position="end">
                        <IconButton onClick={handleButtonClick} size="small">
                            <FileCopyIcon />
                        </IconButton>
                    </InputAdornment>
                </Tooltip>

            ),
        }}
        />
        </div>
    )
}
