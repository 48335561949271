import { gql } from '~/__generated__';

//TODO: figure out description, price, priceUnit
const createReport = gql(/* GraphQL */`
    mutation createReport($resourceIds: [UUID!]!) {
        createResourceReport(
            input: {
               resourceIds: $resourceIds
            }
        ) 
    }
`);

export { createReport }
