import React, {useContext, useEffect} from "react";
import {useLazyQuery} from "@apollo/client";
import {query} from "~/features/graphql";
import {usePersistingTableState} from "~/features/persistingTableState";
import {ALL, ACTIVE_ONLY, ARCHIVED_ONLY} from "~/components/containers/ResourceTable/StatusFilter";
import {type TableState} from "~/features/persistingTableState/PersistingTableStateProvider";
import {
    DateTimeOperationFilterInput, InputMaybe, ResourceFilterInput, ResourceSortInput,
    ResourcesSearchQuery,
    ResourcesSearchQueryVariables
} from "~/__generated__/graphql";

export type SearchContent = {
    searchTerm: string | null;
    searchResources: (cursor?: null) => void;
    handleSearch: (v: string) => void;
    data: ResourcesSearchQuery | undefined,
    loading: boolean;
}

const SearchContext = React.createContext<SearchContent>({
    searchTerm: "",
    searchResources: () => {},
    handleSearch: () => {},
    data: undefined,
    loading: false
});

SearchContext.displayName = 'SearchContext';

function buildOrderByClause(tableContext: TableState): InputMaybe<Array<ResourceSortInput> | ResourceSortInput> {
    if (tableContext.tableState.sortBy.length === 0 || tableContext.tableState.sortBy[0] === undefined)
        return null;

    const orderBy = tableContext.tableState.sortBy[0]?.id ?? 'name';
    const order = tableContext.tableState.sortBy[0]?.desc ? 'DESC' : 'ASC' ?? 'ASC';
    return [{[orderBy]: order}];
} 

function buildFilterByClause(tableContext: TableState): InputMaybe<Array<ResourceFilterInput> | ResourceFilterInput> {
    if (tableContext.tableState.filterBy.length === 0)
        return null;
    
    const filterBy = tableContext.tableState.filterBy[0];
    const filterById = filterBy.id;
    const filterValue = filterBy.value
    
    // When ALL or null we just return NULL which represents no filter clause
    if (filterValue && filterValue === ALL)
        return null;
    
    const innerClause: DateTimeOperationFilterInput | null = filterValue === ACTIVE_ONLY 
        ? { eq: null } 
        : filterValue === ARCHIVED_ONLY 
            ? { neq: null }
            : null;

    if (innerClause === null) return null;
    
    return {[filterById]: innerClause};
}

const useSearch = () => useContext(SearchContext)

const SearchProvider = ({ children }: { children: React.ReactNode }) => {
    const tableContext = usePersistingTableState();
    
    const [searchTerm, setSearchTerm] = React.useState<string>("");

    const [searchServer, { data, loading }] = 
        useLazyQuery<ResourcesSearchQuery, ResourcesSearchQueryVariables>(
        query.resource.GET_RESOURCES_BY_SEARCH_TERM, {
        fetchPolicy: 'cache-and-network',
    });
    
    const handleSearch = (value: string) => {
        setSearchTerm(value);
    };
    
    const searchResources = async (cursor: string | null = null) => {
        const orderClause = buildOrderByClause(tableContext) ?? [];
        const filterClause = buildFilterByClause(tableContext) ?? [];
        
        return await searchServer({
           variables: {
               cursor,
               first: 100,
               contains: searchTerm ?? '',
               orderClause,
               filterClause
           }
        });
    }

    useEffect(() => {
        async function searchResourcesAsyncWrapper() {
            await searchResources();
        }
        
        const timeOutId = setTimeout(searchResourcesAsyncWrapper, 500);
        return () => clearTimeout(timeOutId);
    }, [
        searchTerm, 
        tableContext.tableState.sortBy, 
        tableContext.tableState.filterBy
    ]);

    const searchData = {
        searchTerm,
        handleSearch,
        searchResources,
        data,
        loading
    };
    
    return (
        <SearchContext.Provider value={searchData}>{children}</SearchContext.Provider>
    );
};

export { useSearch, SearchProvider };
