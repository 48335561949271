import React from "react";
import {useMediaQuery, Paper} from "@material-ui/core";
import {StatusProvider} from "~/features/status/StatusProvider";
import {SearchProvider} from "~/features/search/SearchProvider";
import ErrorBoundary from "./ErrorBoundary";

export const BasePage: React.FC = ({children}) => {
    const matches = useMediaQuery("(max-width:80rem)");
    return (
        <StatusProvider>
            <SearchProvider>
                <ErrorBoundary>
                    <Paper
                        square
                        style={{
                            maxWidth: "85rem",
                            margin: "auto",
                            marginTop: matches ? "0" : "2rem",
                            paddingBottom: 16,
                            minHeight: matches ? "calc(100vh - 58.29px)" : "initial",
                        }}
                    >
                        {children}
                    </Paper>
                </ErrorBoundary>
            </SearchProvider>
        </StatusProvider>
    );
};
