import React from "react";
import { NonBasicLocationForm } from "~/components/forms/LocationForm/types";
import { IAddCardForm } from "./types";
import { Grid } from "@material-ui/core";
import { CardForm } from "./CardForm";

export const AddCardForm = (props: IAddCardForm<NonBasicLocationForm>) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CardForm {...props}/>
      </Grid>
    </Grid>
  );
};
