import {
    mutations, query
} from "~/features/graphql";
import {
    useMutation
} from "@apollo/client";
import { constants } from "~/components/containers/LocationFormTemplate/types"
import { useStatus, statusConstants  } from "~/features/status";
import { Resource } from "~/components/forms/ResourceForm";

const useDeleteCardForm = (
    resource: Resource
) => {
    const {
        CONTACTS,
        PRODUCTS,
        OFFERS,
    } = constants;

    const refetchQuery = resource.id ? {
        refetchQueries: [{
            query: query.resource.GET_RESOURCE,
            variables: { id: resource.id }
        }],
        awaitRefetchQueries: true,
    } : {};

    const [deleteContact] = useMutation(mutations.contacts.deleteContact, refetchQuery);
    const [deleteOffer] = useMutation(mutations.offers.deleteOffer, refetchQuery);
    const [deleteProduct] = useMutation(mutations.products.deleteProduct, refetchQuery);
      const [deletePrice] = useMutation(mutations.prices.deletePrice, refetchQuery);
    const { setStatus, status } = useStatus();

    const handleDelete = async (id: string, activeStep: string) => {
        let res;
        const req = { variables: { id } };
        switch (activeStep) {
            case "prices": {
                res = await deletePrice(req);
                break;
            }
            case CONTACTS: {
                res = await deleteContact(req);
                break;
            }
            case OFFERS: {
                res = await deleteOffer(req)
                break;
            }
            case PRODUCTS: {
                res = await deleteProduct(req);
                break;
            }
            default:
                return;
        }
        
        if (status === statusConstants.IDLE) {
            setStatus(statusConstants.SUCCESS, "Successfully deleted record");
        }
        return res
    }
    return handleDelete;
}

export {
    useDeleteCardForm
};
