import React from "react";
import { Grid } from "@material-ui/core"
import { StatusMessage } from "../StatusMessage";
import { useStatus } from "~/features/status";

const HeaderMessage = () => {
    const { message } = useStatus();
    if (!message) return null;
    return (
        <Grid item xs={12}>
            <StatusMessage>
                <div>
                    {message}
                </div>
            </StatusMessage>
        </Grid>
    )
}

export { HeaderMessage }
