import React, { useContext, useState } from "react";
import { Resource } from "~/components/forms/ResourceForm/types"

export type NodesContent = {
    normalizedTableData: any[];
    setNormalizedTableData: (value: any) => void
}

const NodesContext = React.createContext<NodesContent>({
    normalizedTableData: [],
    setNormalizedTableData: (value: any[]) => { }
});

const useNodes = () => useContext(NodesContext)

const NodesProvider = ({ children }: { children: React.ReactNode }) => {

    const [nodes, setNodes] = useState<any[]>([]);

    const handleSetNodes = (value: Resource[]) => {
        setNodes(value);
    };

    return (
        <NodesContext.Provider value={{
            normalizedTableData: nodes,
            setNormalizedTableData: handleSetNodes,
        }}>{children} </NodesContext.Provider>
    );
};

export { useNodes, NodesProvider };
