import { format, parseISO } from "date-fns";

export const normalizeDate = (date: string) => {
    if (!date) return date;
    let _date = date.split('-').join('/');
	let _fdate = new Date(_date);
    _fdate.setHours(12, 0, 0, 0);
    return _fdate.toISOString();
}

export const parseDate = (date: string) => {
    if (!date) return date;
    const _date = parseISO(date)
    if (!isValidDate(_date)) return date;
    return  format(_date, "yyyy-MM-dd")
}

export const getCurrentDay = () => format(new Date(), 'yyyy-MM-dd') + 'T23:59:59.999Z';
function isValidDate(d: any) {
    return !isNaN(d) && d instanceof Date;
  }

 export const formatToPhone = (value: string) => {
    if (!value) return;

    const input = value.replace(/\D/g,'').substring(0,10);
    const areaCode = input.substring(0,3);
    const middle = input.substring(3,6);
    const last = input.substring(6,10);

    if(input.length > 6){return `${areaCode}-${middle}-${last}`;}
    else if(input.length > 3){return `${areaCode}-${middle}`;}
    else if(input.length > 0){return `${areaCode}`;}
};
