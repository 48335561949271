import React from "react";
import { InputFieldProps } from "../library/InputField";
import { IMenuItem } from "../library/SelectField/SelectField";

export interface FormProps<FormRecordType> {
    id?: string;
    classes?: any;
    submit?: any;
    formRef?: React.LegacyRef<HTMLFormElement>;
    initialValues?: FormRecordType;
    handleFormPassesValidation: (passesValidation: boolean) => void;
    passedValidation: boolean;
    onSubmit?: any;
}

export interface FieldProp extends InputFieldProps {
    id?: string;
    component: any;
    options?: Array<IMenuItem>
    fields?: Array<FieldProp>;
    defaultFieldValue?: any;
    defaultValue?: any;
    initialValue?: any;
    format?: (value: string) => any;
    parse?: (value: string) => any;
    InputProps?: any;
    accept?: string;
}

export interface FieldArrayProp extends FieldProp {
    fields: Array<FieldProp>
    defaultFieldValue: any;
}

export const FIELD_ARRAY = "field-array"
