import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

export const ALL = "all";
export const ACTIVE_ONLY = "activeOnly";
export const ARCHIVED_ONLY = "archivedOnly";
export const StatusFilterOptions = [ALL, ACTIVE_ONLY, ARCHIVED_ONLY] as const;
export type StatusFilterOptions = typeof StatusFilterOptions[number];

export const StatusFilter = ({
    value,
    onSelection
}: {
    value: StatusFilterOptions,
    onSelection: (value: StatusFilterOptions) => void
}) => {

    const handleChange = (event: any) => {
        onSelection(event.target.value);
    };

    return (
        <div style={{width: "100%"}}>
            <FormControl variant="outlined" fullWidth>
                <InputLabel id="status-filter-label-id">Status</InputLabel>
                <Select label="Status" labelId="status-filter-label-id" value={value} onChange={handleChange}>
                    <MenuItem value={ALL}>All</MenuItem>
                    <MenuItem value={ACTIVE_ONLY}>Active Only</MenuItem>
                    <MenuItem value={ARCHIVED_ONLY}>Archived Only</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}

export const filterByStatus = (filter: string, data: any) => {
    if (filter === ALL) {
        return data;
    } else if (filter === ACTIVE_ONLY) {
        return data.filter((item: any) => {
            const notExpired = !item.expiredOn || new Date() < new Date(item.expiredOn);
            return !item.archivedOn && notExpired;
        });
    } else if (filter === ARCHIVED_ONLY) {
        return data.filter((item: any) => {
            const notExpired = !item.expiredOn || new Date() < new Date(item.expiredOn);
            return !Boolean(!item.archivedOn && notExpired);
        });
    }
}
