import {
    resource
} from "./resource";
import { categories } from "./categories";
import { companies } from "./companies";

export const query = {
    resource,
    categories,
    companies
};