import { resource } from "./resource";
import { locations } from "./locations"
import { products } from "./products"
import { offers } from "./offers"
import { contacts } from "./contacts"
import { prices } from "./prices";
import { reports } from "./reports"

export const mutations = {
    resource,
    locations,
    contacts,
    offers,
    products,
    prices,
    reports
} 
