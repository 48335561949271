import React from "react";

const Table = React.forwardRef<
    HTMLTableElement, 
    React.TableHTMLAttributes<HTMLTableElement>
>((props, ref) => {
    return <table ref={ref} {...props} />
});

Table.displayName = "Table";

const TableHeader = React.forwardRef<
    HTMLTableSectionElement, 
    React.HTMLAttributes<HTMLTableSectionElement>
>((props, ref) => {
    return <thead ref={ref} {...props} />
});

TableHeader.displayName = "TableHeader";

const TableHead = React.forwardRef<
    HTMLTableCellElement, 
    React.ThHTMLAttributes<HTMLTableCellElement>
>((props, ref) => {
    return <th ref={ref} {...props}/>
});

TableHead.displayName = "TableHead";

const TableBody = React.forwardRef<
    HTMLTableSectionElement, 
    React.HTMLAttributes<HTMLTableSectionElement>
>((props, ref) => {
    return <tbody ref={ref} {...props} />
})

TableBody.displayName = "TableBody";

const TableRow = React.forwardRef<
    HTMLTableRowElement, 
    React.HTMLAttributes<HTMLTableRowElement>
>((props, ref) => {
    return <tr ref={ref} {...props} />
});

TableRow.displayName = "TableRow";

const TableCell = React.forwardRef<
    HTMLTableCellElement, 
    React.TdHTMLAttributes<HTMLTableCellElement>
>((props, ref) => {
    return <td ref={ref} {...props} />
})

TableCell.displayName = "TableCell";

const TableFooter = React.forwardRef<
    HTMLTableSectionElement, 
    React.HTMLAttributes<HTMLTableSectionElement>
>((props, ref) => {
    return <tfoot ref={ref} {...props} />
});

TableFooter.displayName = "TableFooter";

export {
    Table,
    TableHeader,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableFooter,
}
