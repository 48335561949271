import { gql } from "graphql-tag";

const deleteProduct = gql`
    mutation deleteProduct(
            $id: UUID!
    ) {
        deleteProduct(
            input: {
                id: $id
            }
        ) {
        product 
            {
                id
            }
        }
    }
`;

export {
    deleteProduct
}