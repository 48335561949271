import React, { useState } from "react";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { Grid } from "@material-ui/core";
import { Button } from "~/components/library/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {
  IFieldArrayProps
} from "~/components/library/AddCardForm/types";
import { FieldArrayAccordion } from "./FieldArrayAccordion";
import SearchComponent from "./SearchComponent";

export const RenderFieldArray = (props: IFieldArrayProps) => {
  const { recordConstants, recordType } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearch = (value: string) => setSearchTerm(value.trim()); 
  return (
    <FieldArray name={recordConstants.name} mutators={{
      // potentially other mutators could be merged here
      ...arrayMutators,
    }}
    >
      {({ fields }) => {
        return (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              style={{ background: "#fff" }}
              startIcon={<AddCircleIcon color="secondary" />}
              disableProgress={true}
              onClick={() => {
                fields.push(recordConstants.defaultFieldValue);
              }}
            >
              Add new {recordType}
            </Button>
          </Grid>
          <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end"}}>
            <SearchComponent searchTerm={searchTerm} handleSearch={handleSearch} />
          </Grid>
          {fields.map((record, i) => <FieldArrayAccordion record={record} i={i} fields={fields} searchTerm={searchTerm} recordConstants={recordConstants}/>)}
        </Grid>
      )}}
    </FieldArray>
  );
};

