import { gql } from "graphql-tag";

const createOffer = gql`
    mutation createOffer(
            $locationId: UUID!
            $name: String!,
            $description: String,
            $instructions: String!,
            $uri: String,
            $effectiveOn: DateTime!,
            $expiredOn: DateTime,

    ) {
        createOffer(
            input: {
                locationId: $locationId
                name: $name,
                description: $description,
                instructions: $instructions,
                uri: $uri,
                effectiveOn: $effectiveOn,
                expiredOn: $expiredOn,
            }
        ) {
        offer 
            {
                id
                name
                description
                instructions
                uri
                effectiveOn
                expiredOn
            }
        }
    }
`;

export {
    createOffer
}