import React, { Component } from 'react';
import { Container } from "@material-ui/core";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        console.error('Error caught in boundary: ', error, info);
    }

    render() {
        const { hasError } = this.state;
        if (hasError) {
            return (
                <Container style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "60vh"}}>
                    Something went wrong, please try again later
                </Container>
            )
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
