import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { CheckField, Option } from "~/components/library/CheckField";
import { query } from "~/features/graphql";
import { useQuery } from "@apollo/client";

interface Props {
  fields: any;
}

export const CheckFieldForm = (props: Props) => {
  const { fields } = props;
  const { loading, data } = useQuery(query.categories.GET_CATEGORIES);

  const toggle = (checked: boolean, option: string) => {
    if (checked) {
      if (fields.value.includes(option)) return;
      fields.push(option);
    } else {
        const index = fields.value.findIndex((id: string) => id === option);
          fields.remove(index)
      }
    return;
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="subtitle2" className="text-lg font-medium">
          Resource Categories*
        </Typography>
      </Grid>
      {loading
        ? "fetching"
        : data?.categories.length? data?.categories.map((option: Option, index: number) => (
            <Grid item xs={6} sm={4} key={`${index}-category-option`}>
              <CheckField
                index={index}
                checked={Boolean(fields.value && fields.value.includes(option.id))}
                option={option}
                valueKey="id"
                onChange={toggle}
              />
            </Grid>
          )) : "No Category options found."}
    </Grid>
  );
};
