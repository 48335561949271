import React, { useState } from "react";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { InputField } from "../InputField/InputField";
import { SelectFieldProps } from "./SelectField";
import { useStatus } from "~/features/status";

const filter = createFilterOptions<string>();
const deleteDuplicates = (value: string[]): string[] => Array.from(new Set(value));

const Component = (props: SelectFieldProps<string>) => {
  const { options, ...rest } = props;
  const [fieldOptions, setFieldOptions] = useState<string[]>([]);
  const [value, setValue] = useState<string[]>(props.input.value || [])
  const { isLoading } = useStatus();

  React.useEffect(() => {
    if (!props.options || !Array.isArray(props.options))
      return setFieldOptions([])

     // When a new option is added, it's appended to the options array. 
    // For general house keeping, we ensure there's no duplicates 
    /* @ts-ignore */
    const nextOptions = deleteDuplicates([].concat(props.options, fieldOptions, value))
    setFieldOptions(nextOptions)

  }, [props.options, props.input.value])

  React.useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(props.input.value)) {
      props.input.onChange(value);
    }
  }, [value, props.input]);

  return (
    <Autocomplete
      onChange={(event, newValue, reason) => {
        if (newValue.length) {
          const addedValue = newValue[newValue.length - 1];
          if (addedValue && addedValue.includes(",")) {
            const _set = [...newValue, ...addedValue.split(",")].filter((item) => item !== addedValue).map((item) => item.trim());
            const arr = deleteDuplicates(_set);
            setValue(arr)
          } else {
            setValue(deleteDuplicates(newValue));
          }
        } else {
          setValue([])
        }
      }}
      disabled={isLoading}
      value={value || []}
      selectOnFocus
      autoSelect
      autoHighlight
      multiple
      id="tags-outlined"
      options={fieldOptions}
      getOptionLabel={(option) => option}
      getOptionSelected={(option, value) => {
        return option === value;
      }}
      filterSelectedOptions
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (
          params.inputValue !== "" &&
          !value.includes(params.inputValue)
        ) {
          filtered.push(params.inputValue as string);
        }

        return filtered;
      }}
      renderInput={(params) => {
        return <InputField disableMemo={true} {...params} {...rest} />;
      }}
    />
  );
};

export const TagsField = React.memo(Component);
