import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Button, Divider, Paper, Typography } from '@material-ui/core';
import {useNavigate} from "react-router";

import './landingPage.css'

const useStyles = makeStyles(theme => ({
    statusButton: {
        width: "250px",
        height: "150px",
        fontSize: "24px",
        background: "#0068A8",
        color: "#fff",
        marginLeft: "40px",
        marginRight: "40px",
        '&:hover': {
            background: "#003e79",
        },
        '&:disabled': {
            background: "#cfcfcf"
        },
        ['@media (max-width:1024px)']: {
            width: "160px",
            fontSize: "16px",
        },
        ['@media (max-width:780px)']: {
            width: "300px",
            fontSize: "24px",
            marginBottom: "16px"
        }
    },
    flexContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ['@media (max-width:1024px)']: {
            marginLeft: "16px",
            marginRight: "16px",
        },
        ['@media (max-width:780px)']: {
            flexDirection: "column"
        }
    }
}))

interface IStatus {
    title: string;
    id: number;
    disabled: boolean;
    route: string;
}

const statusTypes: IStatus[] = [
    {
        title: "Synapse Resource Management",
        id: 0,
        disabled: false,
        route: "/resources"
    },
    {
        title: "Synapse Top Categories",
        id: 1,
        disabled: true,
        route: "/"
    },
    {
        title: "Identity Verification",
        id: 2,
        disabled: true,
        route: "/"
    }
]

const LandingPage = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    return <div className="min-h-screen bg-gray-100 bg-gradient-to-br from-gray-50 to-gray-200">
        <div className='page-container flex items-center justify-cente'>
            <Paper variant="outlined" className='buttoncontainer flex items-center justify-center flex-col'>
                <div className='mb-16'>
                    <Typography variant='h2'>Welcome to Atlas</Typography>
                </div>
                <div className={classes.flexContainer}>
                    {statusTypes.map((item: IStatus) => (
                        <div key={item.id}>
                            <Button disabled={item.disabled} onClick={() => {
                                if (item.disabled) return;
                                navigate(item.route);
                            }} className={classes.statusButton}>
                                {item.title}
                            </Button>
                        </div>
                    ))}
                </div>
            </Paper>
        </div>
    </div>
}

export default LandingPage;
