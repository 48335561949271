import { gql } from '~/__generated__';

export const GET_RESOURCES_BY_SEARCH_TERM = gql(/* GraphQL */`
    query ResourcesSearch($cursor: String = "", $first: Int, $contains: String, 
        $filterClause: [ResourceFilterInput!] = [], 
        $orderClause: [ResourceSortInput!] = []
    ) {
        resources(first: $first, after: $cursor,
            where: {
                or: [
                    { name: { contains: $contains } }
                    { type: { contains: $contains } }
                ],
                and: $filterClause
            },
            order: $orderClause
        ) {
            __typename
            totalCount
            pageInfo {
                __typename
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
            edges {
                node {
                    __typename
                    id
                    name
                    type
                    description
                    effectiveOn
                    expiredOn
                    archivedOn
                    tags
                    providerTags
                    company {
                        __typename
                        id
                    }
                    files {
                        __typename
                        id
                        fileName
                        category
                    }
                    categories {
                        __typename
                        id
                        name
                    }
                    locations {
                        __typename
                        name
                        id
                        serviceRangeType
                        effectiveOn
                        expiredOn
                        email
                        website
                        phoneNumber
                        helpLine
                        city
                        state
                        street2
                        street
                        postalCode
                        country
                        deletedOn
                        type
                        offers {
                            __typename
                            id
                            name
                            description
                            instructions
                            uri
                            effectiveOn
                            expiredOn
                            deletedOn
                        }

                        contacts {
                            __typename
                            id
                            title
                            name
                            email
                            phoneNumber
                            effectiveOn
                            expiredOn
                            deletedOn
                        }

                        products {
                            __typename
                            id
                            name
                            description
                            effectiveOn
                            expiredOn
                            prices {
                                id
                                description
                                unitPrice
                                unit
                                effectiveOn
                                expiredOn
                            }
                        }
                    }
                }
            }
        }
    }
`);
