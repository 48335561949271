import { InputField } from "../../../library/InputField/InputField";
import { required, composeValidators, isValidURL, effectiveOnLessThanExpiry, expiryAfterEffectiveOn, maxLength } from "../../validation";
import { FieldProp } from "../../form";
import { parseDate, normalizeDate } from "../../utils";

const maxLength255 = maxLength(255, '{{length}}/{{max}}');
const offersFields: Array<FieldProp> = [
    {
        name: "name",
        label: "Offer Title",
        validate: composeValidators(required, maxLength255),
        required: true,
        component: InputField,
        fullWidth: true,
        // item: true,
        wrapperProps: {
            xs: 12,
            sm: 12,
            md: 6
        }
    },
    {
        name: "effectiveOn",
        label: "Effective",
        validate: composeValidators(required, effectiveOnLessThanExpiry),
        component: InputField,
        parse: (value) => normalizeDate(value),
        format: (value) => parseDate(value),
        fullWidth: true,
        required: true,
        // item: true,
        type: "date",
        wrapperProps: {
            xs: 6,
            sm: 6,
            md: 3,
        },
      },
      {
        name: "expiredOn",
        label: "End",
        validate: expiryAfterEffectiveOn,
        component: InputField,
        parse: (value) => normalizeDate(value),
        format: (value) => parseDate(value),
        fullWidth: true,
        // item: true,
        type: "date",
        wrapperProps: {
            xs: 6,
            sm: 6,
            md: 3,
        },
      },
    {
        name: "description",
        label: "Offer Description",
        component: InputField,
        fullWidth: true,
        validate: maxLength255,
        multiline: true,
        // item: true,
        wrapperProps: {
            xs: 12,
        }
    },
    {
        name: "instructions",
        label: "Redemption Instructions",
        validate: composeValidators(required, maxLength255),
        required: true,
        component: InputField,
        fullWidth: true,
        multiline: true,
        // item: true,
        wrapperProps: {
            xs: 6,
        }
    },
    {
        name: "uri",
        label: "Redemption Link",
        validate: isValidURL,
        component: InputField,
        fullWidth: true,
        // item: true,
        wrapperProps: {
            xs: 6,
        }
    },
];

export { offersFields };