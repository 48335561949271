import { gql } from "graphql-tag";

//TODO: figure out description, price, priceUnit
const createProduct = gql`
    mutation createProduct(
            $resourceId: UUID!
            $locationId: UUID!,
            $name: String!,
            $effectiveOn: DateTime!,
            $expiredOn: DateTime,
            $prices: [InnerCreatePriceInput!]
    ) {
        createProduct(
            input: {
               resourceId: $resourceId
               locationId: $locationId
                name: $name
                description: "test"
                effectiveOn: $effectiveOn
                expiredOn: $expiredOn
                prices: $prices
            }
        ) {
        product 
            {
                id
                name
                description
                effectiveOn
                expiredOn
                prices {
                    description
                    unit
                    unitPrice
                    effectiveOn
                    expiredOn
                }
            }
        }
    }
`;

export {
    createProduct
}