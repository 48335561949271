import React from "react";
import { BasePage } from "../BasePage";
import ResourceDataGrid from "~/components/library/ResourceDataGrid/ResourceDataGrid";

export const ResourcesPage = () => {

  return (
    <BasePage>
        <ResourceDataGrid />
    </BasePage>
  );
};
