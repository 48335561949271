import React from "react";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import IconButton from "@material-ui/core/IconButton";
import { Tooltip } from "@material-ui/core";
import { useStatus } from "~/features/status";

interface Props {
  handleOnClick: () => void;
  deleting: Boolean;
}

export const RemoveCardButton = (props: Props) => {
  const { handleOnClick, deleting } = props;
  const { isLoading } = useStatus();
  const handleClick = () => {
    return isLoading ? {} : handleOnClick()
  }
  return (
    <Tooltip placement="top" arrow title={deleting ? "Cancel Delete" : "Remove record"}>
      <IconButton 
        onClick={handleClick}
        size="small"
        style={{ display: "flex", alignItems: "flex-start", marginTop: 8, height: "30px" }}
        >
      <RemoveCircleIcon
        className="text-gray-400"
      />
      </IconButton>
    </Tooltip>
  );
};
