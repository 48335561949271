import { gql } from "graphql-tag";

export const createResource = gql`
mutation CreateResource($type: String!, $name: String!, $description: String!, $effectiveOn: DateTime!, $expiredOn: DateTime, $tags: [String!]!, $providerTags: [String!], $categoryIds: [UUID!]!, $companyId: UUID!) {
  createResource(
    input: {
      companyId: $companyId
      name: $name
      type: $type
      description: $description
      effectiveOn: $effectiveOn
      expiredOn: $expiredOn
      internalTags: $tags
      providerTags: $providerTags
      categoryIds: $categoryIds
    }
  ) {
    resource {
      id
      name
      type
      description
      effectiveOn
      expiredOn
      archivedOn
      tags 
      providerTags
      categories {
        id
      }
       locations {
        name
        id
        serviceRangeType
        effectiveOn
        expiredOn
        email
        website
        phoneNumber
        helpLine
        id
        city
        state
        street2
        street
        postalCode
        country

      offers {
        id
        name
        description
        instructions
        uri
        effectiveOn
        expiredOn
      }

      contacts {
        title
        name
        email
        phoneNumber
      }
    }
    }
  }
}
`;