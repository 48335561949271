import { gql } from "graphql-tag";

const deleteOffer = gql`
    mutation deleteOffer(
            $id: UUID!
    ) {
        deleteOffer(
            input: {
                id: $id
            }
        ) {
        offer 
            {
                id
                name
                description
                instructions
                uri
                effectiveOn
                expiredOn
            }
        }
    }
`;

export {
    deleteOffer
}