import React, { useState } from "react";
import { Field } from "react-final-form";
import { RemoveCardButton } from "../RemoveCardButton"
import { parseDate } from "~/components/forms/utils"
import { IDField } from "~/components/library/InputField";
import { Grid } from "@material-ui/core";
import { FieldProp } from "~/components/forms/form";
import { InputField } from "~/components/library/InputField/InputField";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const NestedFieldArrayAccordion = ({
  record,
  i,
  field,
  fields,
  effectiveOn,
  expiredOn
}: {
  record: any,
  i: number,
  field: any,
  fields: any,
  effectiveOn: any,
  expiredOn: any
}) => {
  const [expanded, setExpanded] = useState(!fields.value[i].id);

  const handleDelete = () => {
    if (fields.value[i].id) {
      if (fields.value[i].shouldDelete) {
        const {
          shouldDelete,
          ...rest
        } = fields.value[i];
        fields.update(i, {
          ...rest
        })
      } else {
        fields.update(i, {
          ...fields.value[i],
          shouldDelete: true
        })
      }
    } else fields.remove(i)
  };

  return (
    <Grid item xs={12} key={`${record}-${i}`}>
      <Accordion
        expanded={expanded}
        style={{ backgroundColor: "#fff", opacity: fields.value[i].shouldDelete ? "0.4" : 1 }}
      >

        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          onClick={() => setExpanded(!expanded)}
          aria-controls={`${record}-action${i}-content`}
          id={`${record}-actions${i}-header`}
          style={{ display: "flex", alignItems: "flex-start", margin: 0, paddingTop: 8 }}
          IconButtonProps={{
            size: "small",
            style: {
              marginTop: 8
            }
          }}
        >

          <Grid container spacing={2}>
            <Grid item xs={expanded ? 9 : 3}>
              {/* @ts-ignore */}
              {fields.value[i]?.id ? <Field name="id" label="Id" InputProps={{ readOnly: true }} fullWidth component={IDField} /> : ""}
            </Grid>
            {!expanded ? <Grid item xs={3}><Field {...field.fields[0]} InputProps={{ readOnly: true }} name={`${record}.unitPrice`} /></Grid> : ""}
            {!expanded ? <Grid item xs={3}><Field {...field.fields[1]} InputProps={{ readOnly: true }} name={`${record}.unit`} /></Grid> : ""}
            <Grid item xs={expanded ? 3 : 3} style={{ display: "flex", justifyContent: "flex-end" }}>
              <RemoveCardButton deleting={Boolean(fields.value[i].shouldDelete)} handleOnClick={handleDelete} />
            </Grid>
          </Grid>

        </AccordionSummary>
        <AccordionDetails>


          <Grid container spacing={2}>
            {field.fields && field.fields.map((field: FieldProp, index: number) => {
              if (field.name === "unitPrice" && !expanded) {
                return ""
              };

              if (field.name === "effectiveOn") {
                return <Grid item key={field.name} {...field.wrapperProps}>
                  <Field {...field} defaultValue={effectiveOn} InputProps={{ inputProps: { min: parseDate(effectiveOn) } }} name={`${record}.${field.name}`} />
                </Grid>
              };

              if (field.name === "expiredOn" && expiredOn) {
                return <Grid item key={field.name} {...field.wrapperProps}>
                  <Field {...field} defaultValue={expiredOn} InputProps={{ inputProps: { max: parseDate(expiredOn) } }} name={`${record}.${field.name}`} />
                </Grid>
              };

              return <Grid
                item
                key={`${index}-field-array-nested`}
                {...field.wrapperProps}
              >
                <Field {...field} name={`${record}.${field.name}`} />
              </Grid>
            })}
          </Grid>
        </AccordionDetails>

      </Accordion>
    </Grid>
  );
}
