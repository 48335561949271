import { Configuration, PopupRequest, LogLevel } from "@azure/msal-browser";

export const msalConfig: Configuration = {
    auth: {
        clientId: import.meta.env.VITE_APP_CLIENT_ID, // The only required field.
        authority: "https://login.microsoftonline.com/20fb2a70-08f3-486c-a260-446946f08b4c", // Defaults to "https://login.microsoftonline.com/common"
        redirectUri: import.meta.env.VITE_OAUTH2_REDIRECT_URL || "/oauth2", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: import.meta.env.VITE_LOGOUT_REDIRECT_URI, // The page to navigate to after being logged out
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response
    },
    cache: {
        "cacheLocation": "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        "storeAuthStateInCookie": false // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii)
                    return;

                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
}

export const loginRequest: PopupRequest = {
    "scopes": [`api://${import.meta.env.VITE_APP_ID}/access_as_user`, "openid", "offline_access"]
}
