import { InputField } from "../../../library/InputField/InputField";
import { required, composeValidators, effectiveOnLessThanExpiry, expiryAfterEffectiveOn, maxLength, minValue1, minValue0 } from "../../validation";
import { FieldProp, FieldArrayProp, FIELD_ARRAY } from "../../form";
import { parseDate, normalizeDate } from "../../utils"
import { Price } from "../types";
// @ts-ignore

const PriceField: Array<FieldProp> = [
  {
    name: "unitPrice",
    label: "Price",
    validate: minValue0,
    required: true,
    component: InputField,
    parse: value => parseFloat(value),
    fullWidth: true,
    type: "number",
    // item: true,
    wrapperProps: {
      xs: 2,
    },
  },
  {
    label: "Price Per",
    name: "unit",
    validate: required,
    required: true,
    component: InputField,
    fullWidth: true,
    multiline: true,
    // item: true,
    wrapperProps: {
      xs: 2,
    },
  },
  {
    name: "effectiveOn",
    label: "Effective",
    validate: composeValidators(required, effectiveOnLessThanExpiry),
    component: InputField,
    parse: (value) => normalizeDate(value),
    format: (value) => parseDate(value),
    fullWidth: true,
    required: true,
    // item: true,
    type: "date",
    wrapperProps: {
      xs: 6,
      sm: 6,
      md: 4,
      lg: 4
    },
  },
  {
    name: "expiredOn",
    label: "End",
    validate: expiryAfterEffectiveOn,
    component: InputField,
    parse: (value) => normalizeDate(value),
    format: (value) => parseDate(value),
    fullWidth: true,
    // item: true,
    type: "date",
    wrapperProps: {
      xs: 6,
      sm: 6,
      md: 4,
      lg: 4
    },
  },
  {
    name: "description",
    label: "Price Explanation",
    component: InputField,
    fullWidth: true,
    // item: true,
    wrapperProps: {
      xs: 12,
    },
  },
  // {
  //   name: "name",
  //   label: "Name",
  //   validate: required,
  //   required: true,
  //   component: InputField,
  //   fullWidth: true,
  //   // item: true,
  //   wrapperProps: {
  //     xs: 6,
  //   },
  // },
];

const productFields: Array<FieldProp | FieldArrayProp> = [
  {
    name: "name",
    label: "Product Name",
    validate: composeValidators(required, effectiveOnLessThanExpiry),
    required: true,
    component: InputField,
    fullWidth: true,
    // item: true,
    wrapperProps: {
      xs: 12,
      sm: 8,
      md: 4,
      lg: 4
    },
  },
  {
    name: "effectiveOn",
    label: "Effective",
    validate: composeValidators(required, effectiveOnLessThanExpiry),
    component: InputField,
    parse: (value) => normalizeDate(value),
    format: (value) => parseDate(value),
    fullWidth: true,
    required: true,
    // item: true,
    type: "date",
    wrapperProps: {
      xs: 6,
      sm: 6,
      md: 4,
      lg: 4
    },
  },
  {
    name: "expiredOn",
    label: "End",
    validate: expiryAfterEffectiveOn,
    component: InputField,
    parse: (value) => normalizeDate(value),
    format: (value) => parseDate(value),
    fullWidth: true,
    // item: true,
    type: "date",
    wrapperProps: {
      xs: 6,
      sm: 6,
      md: 4,
      lg: 4
    },
  },
  {
    name: "prices",
    label: "Price",
    type: FIELD_ARRAY,
    fields: PriceField,
    defaultFieldValue: {
    } as Price,
  } as FieldArrayProp,
];

export { productFields };
