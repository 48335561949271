import React from "react";
import { FormSpy, FormSpyProps } from "react-final-form";
import { useStatus, statusConstants } from "~/features/status";

export interface ISpyComponentProps extends FormSpyProps {
  passedValidation?: boolean;
  handleFormPassesValidation: (passedValidation: boolean) => void;
  exposeValues?: (values: any, valid: boolean) => void;
  exposeErrors?: boolean;
}

export const SpyComponent = (props: ISpyComponentProps) => {
  const {
    handleFormPassesValidation,
  } = props;
  const { setStatus, status } = useStatus();
  return (
    <FormSpy
      subscription={{
        hasValidationErrors: true,
        dirtySinceLastSubmit: true,
        valid: true,
        dirty: true,
        pristine: true,
        values: true
      }}
      onChange={(state) => {
        const {
          valid,
          dirty,
        } = state;

        if (status === statusConstants.SUBMITTING) return;
        if (dirty && valid) {
          setStatus(statusConstants.ACTIVE, statusConstants.PLEASE_SAVE_MESSAGE);
        } else if (!valid) {
          setStatus(statusConstants.ACTIVE);
        } else if (valid) {
          setStatus(statusConstants.IDLE)
        }
        handleFormPassesValidation(valid)
      }}
    />
  );
};
