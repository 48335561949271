import React, {useState} from 'react';
import MuiTableContainer from '@material-ui/core/TableContainer';
import MuiTable from '@material-ui/core/Table';
import MuiTableRow from '@material-ui/core/TableRow';
import TablePagination from "@material-ui/core/TablePagination";
import {ArrowUpward} from "@material-ui/icons";
import {
    type Row,
    type Table,
    type ColumnDef,
    type TableState,
    type PaginationState,
    type RowSelectionState,
    type SortingState,
    type Header as TanStackTableHeader,
    type Updater,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
    getPaginationRowModel,
    SortingTableState,
    functionalUpdate,
} from "@tanstack/react-table";
import {
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableHeader, TableRow,
} from "~/components/library/Table/Table";
import {cx} from "~/library/css/cx";


const isEmpty = (value: any) => value && value.constructor === Object && Object.values(value).length > 0; 
const isFunction = (value: any) => value instanceof Function;

const Input = React.forwardRef<
    HTMLInputElement,
    React.InputHTMLAttributes<HTMLInputElement>
>((props, ref) => (
    <input ref={ref} {...props} />
));

interface ResourceTableProps<TData> {
    columns: ColumnDef<TData>[],
    rows: TData[],
    initialState: { pagination?: Partial<PaginationState> },
    getRowId: (originalRow: TData, index: Number, parent?: Row<TData>) => string,
    toolbarSlot?: (props: Table<TData>) => JSX.Element,
    onStateChange?: (props: TableOperation) => void
}

export interface TableOperation {
    operation: 'row-selection' | 'pagination' | 'sort' | 'state',
    state: RowSelectionState | PaginationState | SortingState | TableState,
}

export function ResourceTable<TData>({ toolbarSlot, onStateChange, initialState, ...props }: ResourceTableProps<TData>): JSX.Element {

    const [sorting, setSorting] = useState<SortingState>([])
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    // const [pagination, setPagination] = useState<PaginationState>({
    //     pageIndex: 0,
    //     pageSize: 25
    // });

    const handleRowSelectionChange = (updater: Updater<RowSelectionState>)  => {
        // @ts-ignore
        const updatedState = functionalUpdate(updater, rowSelection) ?? [];
        onStateChange?.({ operation: 'row-selection', state: updatedState });
        setRowSelection(updater);
    }
    
    const handleSortChange = (updater: Updater<SortingState>) => {
        const updatedState = functionalUpdate(updater, sorting) ?? [];
        onStateChange?.({ operation: 'sort', state: updatedState });
        setSorting(updater);
    }
    
    // const handlePaginationChange = (updater: Updater<PaginationState>) => {
    //     const updatedState = functionalUpdate(updater, pagination) ?? [];
    //     onStateChange?.({ operation: 'pagination', state: updatedState });
    //     setPagination(updater);
    // }
    
    const handleStateChange = (updater: Updater<TableState>) => {
        const updatedState = functionalUpdate(updater, table.getState());
        onStateChange?.({ operation: 'state', state: updatedState })
    }

    const table = useReactTable<TData>({
        columns: props.columns,
        data: props.rows,
        getRowId: props.getRowId,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onRowSelectionChange: handleRowSelectionChange,
        // Disable because when onPaginationChange has a function, the onSortingChange doesn't trigger.
        // Appears to be a bug, will investigate later.
        // onPaginationChange: handlePaginationChange,
        // onSortingChange: handleSortChange,
        initialState: {  pagination: initialState.pagination, sorting: [{ id: 'name', desc: false }] },
        state: {
            rowSelection,
            // pagination,
            // sorting
        },
        onStateChange: handleStateChange,
        debugTable: true,
        manualSorting: true
    });

    const handleSort = (header: TanStackTableHeader<TData, unknown>) => {
        if (header.column.getCanSort()) {
            header.column.toggleSorting(undefined, false);
        }
    }

    const handlePageChange = (e: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        table.setPageIndex(page);
    }

    const handleRowsPerPageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        table.setPageSize(Number(e.target.value))
    }

    return (
        <div style={{margin: 24}}>
            { toolbarSlot ? toolbarSlot(table) : null }
            <MuiTableContainer>
                <MuiTable className='w-full relative overflow-auto table-auto'>
                    <TableHeader className={cx('bg-primary text-white sticky top-0 z-20')}>
                        {table.getHeaderGroups().map(header => (
                            <TableRow key={header.id} id={header.id}>
                                {header.headers.map(head => (
                                    <TableHead key={head.id} id={head.id} 
                                               className={cx(
                                                   'text-left py-0 group/sort-icon', 
                                                   head.id === "select" ? 'w-42 px-0' : 'px-2',
                                                   head.column.columnDef?.meta?.classes,
                                                   '[&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]',
                                                   head.column.getCanSort() && 'cursor-pointer select-none hover:brightness-150')} 
                                               colSpan={head.colSpan} 
                                               onClick={() => handleSort(head)}
                                    >
                                        <div className="flex items-center justify-between group/icon">
                                            {flexRender(head.column.columnDef.header, head.getContext())}

                                            {head.column.getCanSort() && (
                                                <>
                                                    <ArrowUpward fontSize="small"
                                                                 className={cx(
                                                                     '!invisible',
                                                                     head.column.getCanSort() && !head.column.getIsSorted() &&
                                                                     'group-hover/sort-icon:!visible group-hover/sort-icon:opacity-75')} />
                                                    {['asc', 'desc'].includes(String(head.column.getIsSorted())) && (
                                                        <span>
                                                            <ArrowUpward fontSize="small"
                                                                         className={cx(
                                                                             'transform group-hover/icon:transition-transform',
                                                                             head.column.getIsSorted() === "desc" && 'rotate-180'
                                                                         )}/>
                                                        </span>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </TableHead>
                                ))}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody className='divide-y divide-gray-200'>
                        {table.getRowModel().rows?.length === 0 ? (
                            <MuiTableRow className={cx('w-full flex align-center justify-center overflow-hidden p-10')}>
                                <TableCell colSpan={props.columns.length}>No records found.</TableCell>
                            </MuiTableRow>
                        ) : (
                            table.getRowModel().rows.map((row, i) => (
                                <MuiTableRow key={row.id} id={row.id}
                                             data-state={row.getIsSelected() ? "selected" : null}
                                             className={cx(
                                                 'px-6 py-4 hover:bg-gray-100 data-[state=selected]:bg-gray-100', 
                                                 i % 2 ? '' : 'bg-gray-100/30 transition-colors',)}
                                >
                                    {row.getVisibleCells().map(cell => (
                                        <TableCell key={cell.id} id={cell.id} 
                                                   className={cx(
                                                       'text-left py-1', 
                                                       cell.column.id === "select" ? 'w-42px px-0' : 'px-2',)}
                                        >
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </TableCell>
                                    ))}
                                </MuiTableRow>
                            ))
                        )}
                    </TableBody>
                </MuiTable>
            </MuiTableContainer>
            <div className="w-full flex flex-1 flex-row justify-end items-center sticky">
                <TablePagination
                    className="w-full"
                    count={props.rows?.length ?? 0}
                    page={table.getState().pagination.pageIndex}
                    rowsPerPage={table.getState().pagination.pageSize}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            </div>
        </div>
    )
}
