import { gql } from "graphql-tag";

const updateLocation = gql`
    mutation updateLocation(
            $name: String!,
            $id: UUID!,
            $email: String,
            $phoneNumber: String,
            $website: String,
            $helpLine: String,
            $serviceRangeType: String,
            $type: String!,
            $effectiveOn: DateTime!,
            $expiredOn: DateTime,
            $street: String
            $street2: String
            $city: String
            $state: String
            $postalCode: String
            $country: String!
    ) {
        updateLocation(
            input: {
                companyId: "2DA6B4D5-1CA6-4B0A-9696-09F50D99ADA4"
                id: $id
                name: $name
                email: $email
                phoneNumber: $phoneNumber
                website: $website
                helpLine: $helpLine
                serviceRangeType: $serviceRangeType
                type: $type
                effectiveOn: $effectiveOn
                expiredOn: $expiredOn
                
                street: $street
                street2: $street2
                city: $city
                state: $state
                postalCode: $postalCode
                country: $country
            }
        ) {
        location 
            {
                id
                name
                email
                website
                helpLine
                serviceRangeType
                effectiveOn
                expiredOn
                street
                street2
                city
                state
                postalCode
                country
                type
                offers {
                    id
                    name
                    description
                    instructions
                    uri
                    effectiveOn
                    expiredOn
                    deletedOn
                  }
            
                  contacts {
                    id
                    title
                    name
                    email
                    phoneNumber
                    effectiveOn
                    expiredOn
                    deletedOn
                  }
            
                  products {
                    id
                    name
                    description
                    effectiveOn
                    expiredOn
                    prices {
                      id
                      description
                      unitPrice
                      unit
                      effectiveOn
                      expiredOn
                    }
                  }
            }
        }
    }
`;

export {
    updateLocation
}