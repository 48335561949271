import React from "react";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { Grid } from "@material-ui/core";
import { Button } from "~/components/library/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { FieldProp, FieldArrayProp } from "~/components/forms/form";
import { NestedFieldArrayAccordion } from "./NestedFieldArrayAccordion";

interface NestedFieldArrayProps {
  field: FieldArrayProp | FieldProp;
  name: string;
  index?: number
  effectiveOn: string
  expiredOn?: string;
}

export const NestedFieldArray = (props: NestedFieldArrayProps) => {
  const { field, name, effectiveOn, expiredOn } = props;

  return (
    <FieldArray name={name} key={`${name}-nested-array`} mutators={{
      // potentially other mutators could be merged here
      ...arrayMutators,
    }}>
      {({ fields }) => (
        <Grid container spacing={2} style={{ padding: "8px" }}>
          <Grid item xs={12}>
            <Button
              disableProgress={true}
              startIcon={<AddCircleIcon color="secondary" />}
              onClick={() => {
                fields.push(field.defaultFieldValue);
              }}
            >
              Add {field.label}
            </Button>
          </Grid>
          {fields.map((record, i) => {
            return <NestedFieldArrayAccordion record={record} i={i} fields={fields} field={field} effectiveOn={effectiveOn} expiredOn={expiredOn} />
          })}
        </Grid>
      )}
    </FieldArray>
  );
};