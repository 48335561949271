import { gql } from "graphql-tag";

const deleteContact = gql`
    mutation deleteContact(
            $id: UUID!,

    ) {
        deleteContact(
            input: {
                id: $id
            }
        ) {
        contact 
            {
                id
            }
        }
    }
`;

export {
    deleteContact
}