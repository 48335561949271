import { gql } from "graphql-tag";

const GET_CATEGORIES = gql`
  query Categories {
    categories {
      id
      name
      description
    }
  }
`;

export const categories = {
  GET_CATEGORIES
}