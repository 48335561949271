import {useMsal} from '@azure/msal-react';
import {InteractionRequiredAuthError} from "@azure/msal-browser";
import {loginRequest} from "~/features/authorization/authConfig";

const useGetToken = () => {

  const { instance, accounts } = useMsal();
  const getTokenAsync = async () => {
    const account = accounts[0];
    
    if (account) {
      try {
        const result = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0]
        });
        return result.accessToken;
      } catch (err) {
        if (err instanceof InteractionRequiredAuthError)
          return await instance.acquireTokenRedirect(loginRequest);
      } 
    } else {
      return await instance.acquireTokenRedirect(loginRequest);
    }
    
    return null;
  }

  return getTokenAsync;
}

export { useGetToken }