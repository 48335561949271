import { gql } from "graphql-tag";

const createContact = gql`
    mutation createContact(
            $name: String!,
            $title: String,
            $email: String,
            $phoneNumber: String,
            $effectiveOn: DateTime!,
            $expiredOn: DateTime,
            $locationId: UUID!

    ) {
        createContact(
            input: {
                companyId: "2DA6B4D5-1CA6-4B0A-9696-09F50D99ADA4"
                name: $name
                title: $title
                phoneNumber: $phoneNumber
                email: $email
                effectiveOn: $effectiveOn
                expiredOn: $expiredOn
                locationId: $locationId
            }
        ) {
        contact 
            {
                id
                title
                name
                email
                phoneNumber
            }
        }
    }
`;

export {
    createContact
}