import {
    GET_RESOURCE
} from "./resourceById";
import {
    GET_RESOURCES
} from "./resources";
import { 
    resourceFragment
} from "./resourceFragment";
import {
    GET_RESOURCE_BY_NAME
} from "./resourceByName"
import {
    GET_RESOURCES_BY_SEARCH_TERM
} from "./resourceBySearchTerm";

const resource = {
    GET_RESOURCE,
    GET_RESOURCES,
    resourceFragment,
    GET_RESOURCE_BY_NAME,
    GET_RESOURCES_BY_SEARCH_TERM
}

export {
    resource
}
