import React, { useRef, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import { InputField } from "../InputField";


const Component = (props: any) => {
    const { input, validateWidth = true } = props;
    const inputFileRef = useRef<HTMLInputElement>(null);
    const [selectedFile, setSelectedFile] = React.useState<any>(null);
    const [filename, setFilename] = React.useState("");
    const [enableDelete, setEnableDelete] = React.useState(false);

    useEffect(() => {
        if (props.meta.initial && Object.keys(props.meta?.initial) && props.meta.initial?.fileName) {
            setFilename(props.meta.initial.fileName);
            setEnableDelete(true);
        }
    }, [props.meta.initial]);

    useEffect(() => {
        if (props.input?.value && props.input?.value?.file?.name && props.input.value.file.name !== filename) {
            setFilename(props.input.value.file.name);
            setEnableDelete(true);
        }
    }, [props.input.value])

    const handleCapture = ({ target }: any) => {
        if (validateWidth && props.accept === "image/*") {

            const fr = new FileReader();

            fr.onload = function () {
                const img = new Image();

                img.onload = function () {
                    const val = {
                        width: img.width,
                        height: img.height,
                        file: target.files[0],
                        dirty: true
                    }
                    setSelectedFile(val);
                    input.onChange(val);
                    if (props.onChange) {
                        props.onChange(true);
                    }
                };
                img.src = fr.result?.toString() ?? '';

            };

            fr.readAsDataURL(target.files[0]);
        } else {
            input.onChange({
                file: target.files[0],
                dirty: true
            });
            setSelectedFile({
                file: target.files[0],
                dirty: true
            })
            if (props.onChange) {
                props.onChange(true);
            }
        }
        setFilename(target.files[0].name);
        setEnableDelete(true);
    };

    const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        if (enableDelete) {
            setSelectedFile(null);
            setFilename("");
            setEnableDelete(false);
            if (props.input.value) {
                props.input.onChange(null)

            }
            if (props.onChange) {
                props.onChange(false);
            }

        } else {
            inputFileRef.current?.click();
        }
    };

    const {
        input: {
            value,
            onChange,
            ...rest
        },
        name,
        ...restOfProps
    } = props;
    const mimeProps = props.accept ? {
        accept: props.accept
    } : {}
    return <>
        <input type="file" key={`${filename || name}-file-input`} {...rest} {...mimeProps} ref={inputFileRef} onChange={handleCapture} style={{ display: "none" }} />
        <InputField
            {...restOfProps}
            input={{
                value: filename,
                onChange: () => { }
            }}
            onChange={() => { }}
            placeholder="Add Logo"
            InputProps={{
                readOnly: true,
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={handleButtonClick} size="small">
                            {enableDelete ? <DeleteIcon /> : <SearchIcon />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            margin="dense"
        />
    </>
};

function propsAreEqual(prevProps: any, nextProps: any) {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps)
}

export const FileInputField = React.memo(Component, propsAreEqual);
