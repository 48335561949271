import { gql } from "graphql-tag";

const createPrice = gql`
    mutation createPrice(
            $productId: UUID!
            $description: String,
            $unit: String!,
            $unitPrice: Decimal!
            $effectiveOn: DateTime!,
            $expiredOn: DateTime,

    ) {
        createPrice(
            input: {
                productId: $productId
                description: $description,
                unit: $unit,
                unitPrice: $unitPrice,
                effectiveOn: $effectiveOn,
                expiredOn: $expiredOn,
            }
        ) {
        price 
            {
                id
                description
                unit
                unitPrice
                effectiveOn
                expiredOn
            }
        }
    }
`;

export {
    createPrice
}