import { gql } from '~/__generated__';
export const GET_RESOURCES = gql(/* GraphQL */`
    query Resources($cursor: String, $first: Int) {
      locationOptionTypes: appTypes(type: LOCATION) {
        value
      }
      resourceOptionTypes: appTypes(type: RESOURCE) {
        value
      }
      resources(first: $first, after: $cursor, order: {
        name: ASC
      }) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        edges {
          node {
            id
            name
            type
            description
            effectiveOn
            expiredOn
            archivedOn
            tags 
            company {
              id
            }
            files {
              id
              fileName
              category
            }
            categories {
              id
              name
            }
            locations {
              name
              id
              serviceRangeType
              effectiveOn
              expiredOn
              email
              website
              phoneNumber
              helpLine
              city
              state
              street2
              street
              postalCode
              country
              deletedOn
              type
              offers {
                id
                name
                description
                instructions
                uri
                effectiveOn
                expiredOn
                deletedOn
              }
    
              contacts {
                id
                title
                name
                email
                phoneNumber
                effectiveOn
                expiredOn
                deletedOn
              }
    
              products {
                id
                name
                description
                effectiveOn
                expiredOn
                prices {
                  id
                  description
                  unitPrice
                  unit
                  effectiveOn
                  expiredOn
                }
              }
            }
          }
        }
      }
    }
`);
