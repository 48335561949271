import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import {
  FinalFormFieldProps,
  InputProps,
} from "../InputField/InputField";
import { useStatus } from "~/features/status";


export interface IMenuItem {
  label?: string;
  value: any;
};

export interface SelectFieldProps<T> extends FinalFormFieldProps {
  options: Array<T>;
};

export const SelectField = (props: SelectFieldProps<IMenuItem>) => {
  const {
    label,
    input = {} as InputProps,
    options,
    required = false
  } = props;
  const fieldLabel = required ? `${label}*` : label;
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    input.onChange(event.target.value as string);
  };
  const { isLoading } = useStatus();
  return (
    <FormControl fullWidth variant="outlined" disabled={isLoading}>
      <Typography variant="subtitle2" className="text-lg font-medium">{fieldLabel}</Typography>
      <Select {...input} margin="dense" onChange={handleChange} defaultValue="">
        {
          !options.length && input.value ? <MenuItem
            value={input.value}
            key={`${input.name}-loading-menu-item`}
          >
            loading...
          </MenuItem> : options.map((item, index) => {
            return (
              <MenuItem
                value={item.value}
                key={`${index}-${input.name}-menu-item`}
              >
                {item.label || item.value}{" "}
              </MenuItem>
            );
          })
        }
      </Select>
    </FormControl>
  );
};
