import React from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid, { GridTypeMap } from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import Box, { BoxProps } from "@material-ui/core/Box";
import { useStatus } from "~/features/status";
import ClearIcon from '@material-ui/icons/Clear';

const CUSTOM = "custom";
export const DEFAULT = "default";

export interface InputFieldProps {
  name: string;
  mode?: string;
  required?: boolean;
  label?: string;
  helperText?: string;
  item?: boolean;
  wrapperProps?: any;
  validate?: any;
  fullWidth?: boolean;
  type?: string;
  multiline?: boolean;
  rows?: number;
  onChange?: (value: any) => {}
}

export interface FinalFormFieldProps extends InputFieldProps {
  input: InputProps;
  meta: Meta;
  style?: any;
  inputColor?: string;
  InputProps?: any;
  disableMemo?: boolean;
  inputProps: any;
  memoHandler?: any;
}

export interface Meta {
  error: string | null;
  pristine: boolean;
  touched: boolean;
  dirty: boolean;
  active: boolean;
  initial: any;
}

export interface InputProps {
  name: string;
  onBlur: (event?: React.FocusEvent) => void;
  onChange: (event: React.ChangeEvent | any) => void;
  onFocus: (event?: React.FocusEvent) => void;
  type?: string;
  value?: any;
}

export const Component = (props: FinalFormFieldProps) => {
  const {
    disableMemo,
    required = false,
    label,
    helperText = "",
    item = false,
    wrapperProps = {},
    mode = CUSTOM,
    inputColor = "default",
    meta = {} as Meta,
    input = {} as InputProps,
    ...rest
  } = props;

  const {
    error = "",
    touched = false,
  } = meta;

  React.useEffect(() => {
    if (props.onChange) {
      props.onChange(input.value)
    }
  }, [input.value, props.onChange])

  const hasError = Boolean(error && (props.InputProps?.readOnly ? true : touched));
  const isCustom = mode === CUSTOM;
  const fieldLabel = required ? `${label}*` : label;
  const _helperText = hasError
    ? error
    : helperText


  const dateTypeProps = input.type && input.type === "date" && !props.InputProps?.readOnly && input.value ? {
    startAdornment: (
      <InputAdornment position="start">
        <Tooltip title="Clear">
          <IconButton
            onClick={() => {
              input.onChange(null)
              input.onBlur()
            }}
            disabled={!input.value} size="small" style={{ order: 1, padding: "0" }}>
            <ClearIcon color="disabled" fontSize="small" />
          </IconButton>
        </Tooltip>
      </InputAdornment>
    )
  } : {};


  const Wrapper:
    | React.ComponentType<BoxProps>
    | OverridableComponent<GridTypeMap> = item ? Grid : Box;

  const containerProps = item ? { item: true, ...wrapperProps } : {}

  const { isLoading } = useStatus();

  return (
    <Wrapper {...containerProps}>
      {isCustom ? <Typography variant="subtitle2" className="text-lg font-medium">{fieldLabel}</Typography> : ""}
      <TextField
        disabled={isLoading || Boolean(props.InputProps?.readOnly)}
        margin={isCustom ? "dense" : "normal"}
        variant="outlined"
        label={isCustom ? "" : fieldLabel}
        helperText={_helperText}
        error={hasError}
        {...rest}
        {...input}
        style={{ marginTop: "0px", ...rest.style }}
        InputProps={{
          style: {
            background: inputColor === "default" ? "#f8f8f8" : inputColor
          },
          ...props.InputProps,
          ...dateTypeProps,
        }}
        inputProps={{
          style: !!props.multiline
            ? {
              fontSize: '1rem',
              padding: '4.5px 0px',
            }
            : {
            },
          ...props.inputProps,
        }}
      />
    </Wrapper>
  );
};

const propsAreEqual = (prevProps: FinalFormFieldProps, nextProps: FinalFormFieldProps) => {
  if (prevProps.disableMemo) return false;
  if (prevProps.memoHandler) return prevProps.memoHandler(prevProps, nextProps);
  return JSON.stringify(prevProps.meta) === JSON.stringify(nextProps.meta)
    && prevProps.input.value === nextProps.input.value
}

export const InputField = React.memo(Component, propsAreEqual)
