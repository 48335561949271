import React from "react";
import { 
    TabPanelProps,
  } from "./types";
  import { Box } from "@material-ui/core";

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`form-tabpanel-${index}`}
        aria-labelledby={`form-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  }