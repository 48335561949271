import React from "react";
import { Form, FormSpy } from "react-final-form";
import { FormApi } from "final-form";
import arrayMutators from "final-form-arrays";
import {
  contactFields,
  productFields,
  offersFields,
} from "~/components/forms/LocationForm/fields";
import {
  NonBasicLocationForm,
  Contacts,
  Product,
  Offers,
  Location
} from "~/components/forms/LocationForm/types";
import { IAddCardForm, fieldConstant } from "./types";
import { RenderFieldArray } from "./RenderFieldArray";
import { useDeleteCardForm } from "./useDeleteCardForm";
import { useStatus, statusConstants } from "~/features/status";

export const CardForm = (props: IAddCardForm<NonBasicLocationForm>) => {
  const {
    onSubmit,
    recordType,
    step,
    formRef,
    exposeValues,
    selected,
    records,
    resource,
    handleFormPassesValidation
  } = props;

  const { setStatus, status } = useStatus();

  const getFields: () => fieldConstant = () => {
    const defaultDateField = {
      effectiveOn: resource.effectiveOn, 
      expiredOn: resource.expiredOn
    }
    switch (step) {
      case 1: {
        return {
          fields: contactFields,
          name: "contacts",
          defaultFieldValue: {
            ...defaultDateField
          } as Contacts,
        };
      }
      case 2: {
        return {
          fields: productFields,
          name: "products",
          defaultFieldValue: {
            ...defaultDateField
          } as Product,
        };
      }
      case 3: {
        return {
          fields: offersFields,
          name: "offers",
          defaultFieldValue: {
            ...defaultDateField
          } as Offers,
        };
      }
      default:
        return {
          fields: [],
          name: "",
          defaultFieldValue: {
            ...defaultDateField
          },
        };
    }
  };

  const recordConstants: fieldConstant = getFields();

  const handleDelete = useDeleteCardForm(resource);
  const handleSubmit = async (values: any, form: FormApi) => {
    if (values[recordType]?.length) {
      await values[recordType].forEach((record: any) => {
        if (step === 2 && record.prices?.length) {
          record.prices?.forEach((item: any) => {
            if (item.shouldDelete) {
              if (status !== statusConstants.SUBMITTING) {
                setStatus(statusConstants.SUBMITTING)
              }
              handleDelete(item.id, "prices");
            }
          })
        }
        if (record.shouldDelete) {
          if (status !== statusConstants.SUBMITTING) {
            setStatus(statusConstants.SUBMITTING)
          }
          handleDelete(record.id,  recordConstants.name)
        }
      })
    };

    // find dirty fields, submit with values
    let dirtyFields = Object.keys(form.getState().dirtyFields).map((item: string) => item.split(".")[0]);
    if (dirtyFields.length > 1) {
      const fieldsToSubmit = Array.from(new Set(dirtyFields));
      let index: number[] = [];
      fieldsToSubmit.forEach((field) => {
        if (field === recordConstants.name) return;
        index.push(parseInt(field[field.length - 2]))
      })
      await onSubmit(values, index);
      form.reset();
    }
  };

  const checkDirty = (a: Location, b: Location) => { 
    const type = recordConstants.name as keyof Location;

      if (!a[type] || !b[type]) {
        return false;
      }
      return JSON.stringify(a[type]) !== JSON.stringify(b[type]);
  }

  return (
    <Form
      key={`${selected}${recordType}-final-form`}
      onSubmit={(values: any, form: FormApi) => {
        handleSubmit(values, form)
      }}
      initialValues={records[selected]}
      mutators={{
        // potentially other mutators could be merged here
        ...arrayMutators,
      }}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit} ref={formRef}>
            <RenderFieldArray
              key={`${recordType}`}
              recordConstants={recordConstants}
              recordType={recordType}
            />
            <FormSpy
              subscription={{
                values: true,
                valid: true,
                pristine: true,
                dirty: true,
                initialValues: true,
              }}
              onChange={(state) => {
                const { values, valid, pristine, initialValues } = state;
                //@ts-ignore
                const dirty = checkDirty(initialValues, values);
                if (status === statusConstants.SUCCESS) {};
                if (!values[recordType] || !values[recordType].length) {
                  handleFormPassesValidation(true)
                  setStatus(statusConstants.IDLE)
                  return;
                };
                if (pristine) {
                  if (status !== statusConstants.IDLE || status !== statusConstants.SUCCESS) {
                    setStatus(statusConstants.IDLE)
                  }
                  return;
                };
                if (dirty) {
                  if (valid) {
                    setStatus(statusConstants.ACTIVE, statusConstants.PLEASE_SAVE_MESSAGE);
                  } else {
                    setStatus(statusConstants.ACTIVE)};
                } else {
                  setStatus(statusConstants.IDLE)
                }
                exposeValues(values[recordConstants.name], valid, recordType);
              }}
            />
          </form>
        );
      }}
    />
  );
};
