import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@material-ui/core";
import { Header } from "~/components/library/Header";
import { Tabs } from "~/components/library/Tabs";
import { Resource, ResourceForm } from "~/components/forms/ResourceForm";
import { LocationFormTemplate } from "../LocationFormTemplate";
import { useSubmitResource } from "./useSubmitResource";

interface Props {
  resource?: Resource;
  setCurrentId: (id: string) => void;
}

export const ResourceFormTemplate = (props: Props) => {
  const { resource, setCurrentId } = props;

  const formRef = useRef<HTMLFormElement>(null);

  const [activeStep, setActiveStep] = useState<number>(0);
  const [passedValidation, setPassedValidation] = useState<boolean>(Boolean(resource?.id));

  const [handleSubmit, loading] = useSubmitResource(setActiveStep, resource, setPassedValidation, setCurrentId);

  const _handleSubmit = () => {
    if (formRef && formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  };

  const renderForm = () => {
    switch (activeStep) {
      case 0: {
        return (
          <ResourceForm
            id={resource?.id}
            onSubmit={handleSubmit}
            initialValues={resource}
            formRef={formRef}
            passedValidation={passedValidation}
            handleFormPassesValidation={setPassedValidation}
          />
        );
      }
      case 1: {
        const _initialValue = resource?.locations ? [...resource?.locations] : [];
        return (
          <LocationFormTemplate
            //@ts-ignore
            resource={resource}
            initialValues={_initialValue}
            formRef={formRef}
            passedValidation={passedValidation}
            handleFormPassesValidation={setPassedValidation}
          />
        );
      }
    }
  };

  const subtitle = resource?.name ? `Detail View - ${resource.name}` : "Detail View";

  return (
    <Grid
      container
      spacing={4}
      style={{ margin: 0, width: "100%", padding: 16 }}
    >
      <Header title="Synapse Resource Management" subtitle={subtitle} disableDivider={Boolean(activeStep)}>
        <Grid item xs={12} className="pt-4 pb-4">
          <Tabs
            activeStep={activeStep}
            handleSetActiveStep={setActiveStep}
            onSubmit={_handleSubmit}
            passedValidation={passedValidation}
            isEditing={Boolean(resource?.id)}
            submitting={loading}
          />
        </Grid>
      </Header>
      <Grid item xs={12} style={{ minHeight: 600 }}>
        {renderForm()}
      </Grid>
    </Grid>
  );
};
