import { format } from 'date-fns';
import { parseDate, parseDateNew, parseDateTime } from './date.js';

export const dateRenderer = (date, dateFormat = 'MM/dd/yyyy') => {
    return date ? format(parseDate(date), dateFormat) : null;
};

/**
 * @typedef {Object} DateTimeRenderOptions
 * @property {boolean} [dateOnly=false] when true, ignore the time component of the source datetime string when parsing.
 * @property {string} [defaultDateFormat=MM/dd/yyyy] datetime display format
 */

/**
 * Using a ISO8601 date object as a source render to a user desired display format.
 *
 * @param {Date | string} dateTime source Date object or datetime string
 * @param {DateTimeRenderOptions} options
 * @returns {string|null} formatted display date
 */
export const dateTimeRender = (dateTime, {dateOnly = false, defaultDateFormat = 'MM/dd/yyyy'}) => {
    if (!dateTime) return null;
    const parsed = dateOnly ? parseDateNew(dateTime) : parseDateTime(dateTime);
    return parsed ? format(parsed, defaultDateFormat) : null;
}
