import {
    updateContact
} from "./updateContact";
import {
    createContact
} from "./createContact";
import {
    deleteContact
} from "./deleteContact";

const contacts = {
    createContact,
    updateContact,
    deleteContact
}

export {
    contacts
}