import React from "react";
import Paper from "@material-ui/core/Paper";
import { Button as MuiButton } from "@material-ui/core";
import { Button } from "~/components/library/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useStatus, statusConstants } from "~/features/status";
import {useNavigate} from "react-router";

interface TabsProps {
  activeStep: number;
  handleSetActiveStep: (num: number) => void;
  onSubmit: any;
  passedValidation: boolean;
  submitting: boolean;
  isEditing: boolean;
}

export function Component(props: TabsProps) {
  const { activeStep, handleSetActiveStep, passedValidation, submitting } = props;
  
  const navigate = useNavigate();
  const { isLoading, status, setStatus } = useStatus();
  const matches = useMediaQuery("(min-width:628px)");
  
  
  const isActive = status === statusConstants.ACTIVE;
  const allDone = activeStep === 1 && Boolean(status === statusConstants.IDLE || status === statusConstants.SUCCESS);
  const setNext = activeStep === 0 && Boolean(status === statusConstants.IDLE || status === statusConstants.SUCCESS);
  const submitButtonText = allDone ? "All Done" : setNext ? "Next" : "Save";

  const handleBack = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate('/resources');
  };

  const handleNext = () => {
    if (allDone) {
      setStatus(statusConstants.SUCCESS, "Records Saved!")
      navigate('/resources');
    } else if (setNext) {
      handleSetActiveStep(1);
    } else props.onSubmit();
  };

  return (
    <Paper
      square
      elevation={0}
      style={{ marginBottom: "-2px", paddingTop: 8, paddingBottom: 8 }}
    >
      <Grid container>
        <Grid item xs={8} sm={!matches ? 8 : 6} md={6}>
          <ButtonGroup disableElevation color="primary">
            <MuiButton
              disableElevation
              disableFocusRipple
              onClick={() => handleSetActiveStep(0)}
              variant={activeStep === 1 ? "outlined" : "contained"}
              disabled={activeStep === 1 && Boolean(!passedValidation || submitting || isLoading || isActive)}
            >
              General
            </MuiButton>
            <MuiButton
              disableElevation
              disableFocusRipple
              variant={activeStep === 0 ? "outlined" : "contained"}
              onClick={() => handleSetActiveStep(1)}
              disabled={activeStep === 0 && Boolean(!passedValidation || submitting || isLoading || isActive)}
            >
              Location
            </MuiButton>
          </ButtonGroup>
        </Grid>
        {matches ? (
          <Grid
            item
            xs={6}
            className="flex"
            justify="flex-end"
            alignItems="center"
            container
          >
            <MuiButton
              className="w-32"
              onClick={handleBack}
              disabled={isLoading}
            >
              Cancel
            </MuiButton>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disableElevation
              className="w-32 p-1"
              disabled={!passedValidation || submitting || isLoading}
            >
              {submitButtonText}
            </Button>
          </Grid>
        ) : (
          <Grid
            item
            xs={4}
            className="flex"
            justify="flex-end"
            alignItems="center"
            container
          >
            <Button
              color="primary"
              onClick={handleNext}
              disabled={!passedValidation || submitting || isLoading}
              disableElevation
            >
              {submitButtonText}
            </Button>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}
