
import { AddCardForm } from "~/components/library/AddCardForm";
import { steps } from "~/components/containers/LocationFormTemplate/types";
import { LocationForm } from "~/components/forms/LocationForm";


export const useForms = (
    formProps: any
) => {

    return [
        {
            component: <LocationForm {...formProps} initialValues={formProps.records[formProps.selected]} />,
            value: 0,
            name: "Basic",
        },
        {
            component: <AddCardForm {...formProps} />,
            value: 1,
            name: steps[1],
        },
        {
            component: <AddCardForm {...formProps} />,
            value: 2,
            name: steps[2],
        },
        {
            component: <AddCardForm {...formProps} />,
            value: 3,
            name: steps[3],
        },
    ]
}
