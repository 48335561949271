import { createProduct } from "./createProduct";
import { updateProduct } from "./updateProduct";
import { deleteProduct } from "./deleteProduct";

const products = { 
    updateProduct,
    createProduct,
    deleteProduct
}
export {
    products
}