import React from "react";
import { Link } from "react-router-dom";
import {useNavigate} from "react-router";
import {default as MUIAppBar} from "@material-ui/core/AppBar";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import logo from "../../../assets/healthebiography-logo.png";

export type AppBarProps = {
  disableAction?: boolean
}

export default (({  disableAction = false }: AppBarProps) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear() //TODO: fix msal logout function
    navigate('/')
    window.location.reload();
    return false;
  }
  return (
    <MUIAppBar position="static" color="primary" className="p-2 pl-5 cursor-default w-screen flex justify-between flex-row" style={{ flexDirection: "row" }}>
      <Link to="/">
        <img width="240" src={logo} alt="Agrin Health" />
      </Link>
      {disableAction ? "" : <Tooltip title="logout">
        <IconButton onClick={() => handleLogout()} style={{ color: "#fff" }}>
          <ExitToAppIcon />
        </IconButton>
      </Tooltip>}
    </MUIAppBar>
  );
})
