import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditBorder from '@material-ui/icons/BorderColor';
import DeleteBorder from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import { useMutation } from "@apollo/client";
import { mutations } from "~/features/graphql";
import { useStyles } from './styles';
import { useStatus, statusConstants } from "~/features/status";
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useNodes } from "~/features/nodes/NodesProvider";
import { IconButton } from '@material-ui/core';
import { Link } from '@material-ui/icons';

const iconStyles = isDeleting => ({
  color: '#757575',
  opacity: isDeleting ? '.5' : 1,
  cursor: isDeleting ? "default" : 'pointer',
  margin: '0 8px',
  display: isDeleting ? "none" : "block"
});

const Control = ({ row, onEdit }) => {
  const [website, setWebsite] = useState(null);
  const id = row.original.id;
  const classes = useStyles();
  const { normalizedTableData, setNormalizedTableData } = useNodes()

  const [setForDelete, setSetForDelete] = useState(false);
  const [deleteResource, { loading }] = useMutation(mutations.resource.deleteResource, {
    awaitRefetchQueries: true
  });

  const { setStatus } = useStatus();

  const handleDelete = async (id) => {
    await deleteResource({ variables: { id } });
    setNormalizedTableData(normalizedTableData.filter((item) => item.id !== id));
    setSetForDelete(false);
    setStatus(statusConstants.SUCCESS, "Successfully deleted resource")
  };

  const setForDeletion = (_id) => {
    if (setForDelete) {
      setSetForDelete(false);
    } else setSetForDelete(_id)
  }

  const buttonProps = {
    row,
    isDeleting: loading
  }

  useEffect(() => {
    if (row.original.locations.length) {
      const mainLoc = row.original.locations.find((loc) => loc.type === "Main");
      if (mainLoc && mainLoc.website && mainLoc.website.length) {
        setWebsite(mainLoc.website)
      }
    }
  }, [row]);

  const handleLink = () => window.open(website, "_blank")

  return (
    <div className={classes.controlContainer}>
      <LinkButton 
        isDeleting={loading || setForDelete === id}
        handleLink={handleLink}
        website={website}
      />
      <EditButton
        id={id}
        isDeleting={loading || setForDelete === id}
        onEdit={onEdit}
        classes={classes}
        row={row.original}
      />
      {loading ? (
        <div className={classes.progressContainer}>
          <CircularProgress size={24} disableShrink />
        </div>
      ) : setForDelete === id ? (
        <div className={classes.progressContainer}>
          <ConfirmDeleteButton onDelete={handleDelete} {...buttonProps} />
          <CancelDeleteButton onClick={setForDeletion} {...buttonProps} />
        </div>
      ) : (
        <DeleteButton
          onClick={setForDeletion}
          {...buttonProps}
        />
      )}
    </div>
  );
};

const LinkButton = ({ handleLink, website, isDeleting }) => {
  return  <Tooltip title="Go to Resource Website">
    <Link style={iconStyles(isDeleting || !website)} onClick={() => handleLink()} />
  </Tooltip>
}

const EditButton = ({ isDeleting, onEdit, row }) => (
  <Tooltip title={isDeleting ? "" : "Edit Record"}>
    <EditBorder style={iconStyles(isDeleting)} onClick={() => onEdit(row)} />
  </Tooltip>
);

const DeleteButton = ({ row, onClick, isDeleting }) => (
  <Tooltip title="Delete Record">
    <DeleteBorder
      style={iconStyles(isDeleting)}
      onClick={() => onClick(row.original.id)}
    />
  </Tooltip>
);

const ConfirmDeleteButton = ({ row, onDelete, isDeleting }) => (
  <Tooltip title="Confirm Delete">
    <CheckCircleIcon
      style={{ ...iconStyles(isDeleting), color: "#10B981" }}
      onClick={async () => await onDelete(row.original.id)}
    />
  </Tooltip>
);

const CancelDeleteButton = ({ row, onClick, isDeleting }) => (
  <Tooltip title="Cancel">
    <CancelIcon
      style={{ ...iconStyles(isDeleting), color: "#FCD34D", marginRight: 0 }}
      onClick={() => onClick()}
    />
  </Tooltip>
);

export default Control;
