import { gql } from "graphql-tag";

const deleteLocation = gql`
    mutation deleteLocation(
            $id: UUID!
    ) {
        deleteLocation(
            input: {
                id: $id
            }
        ) {
        location 
            {
                id
            }
        }
    }
`;

export {
    deleteLocation
}