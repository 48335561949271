import {ReactNode, useEffect} from "react";
import {useNavigate} from "react-router";

const PanelContainer: React.FC = ({ children }) => {
    return (
        <div className="container lg:w-1/3 sm:w-1/2 shadow-md p-7 mx-auto my-auto bg-white">
            {children}
        </div>
    )
}

const OAuthPage = ({ children }: { children?: ReactNode }) => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/');
    }, []);

    return (
        <PanelContainer>
            Authenticating...
        </PanelContainer>
    );
}

export default OAuthPage;
