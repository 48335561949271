import { useState } from "react";
import { mutations, query } from "~/features/graphql";
import { Resource } from "~/components/forms/ResourceForm";
import { useStatus, statusConstants } from "~/features/status";
import { useGetToken } from "~/features/authorization/useGetToken";
import { useMutation, useApolloClient } from "@apollo/client";
import { useNodes } from "~/features/nodes/NodesProvider";
import { parseDate } from "~/components/forms/utils";

const fileUrl = import.meta.env.VITE_FILE_API_URL as string;

export const submitFile = async (payload: any, headers: any) => {
  await fetch(
    fileUrl, {
    method: 'POST',
    body: payload,
    headers
  });
}

export const deleteFile = async (payload: any, headers: any) => {
  await fetch(
    `${fileUrl}/${payload}`, {
    method: 'DELETE',
    headers
  });
}

export const updateFile = async (id: any, description: string, headers: any) => {
  await fetch(
    `${fileUrl}/${id}/${description}`, {
    method: 'PUT',
    headers
  });
}

function clean(obj: any) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined || !obj[propName].length) {
      delete obj[propName];
    }
  }
  return obj
}

export const useSubmitResource = (
  setActiveStep: any,
  currRecord: Resource | undefined,
  setPassedValidation: any,
  setCurrentId: (id: string) => void,
) => {

  const [loading, setLoading] = useState<boolean>(false);
  const { normalizedTableData, setNormalizedTableData } = useNodes()
  const getTokenAsync = useGetToken();
  const client = useApolloClient();

  const { setStatus } = useStatus()
  // create resource
  const [createResource] = useMutation(mutations.resource.createResource);

  const [createLocation] = useMutation(mutations.locations.createLocation);

  // update resource
  const [updateResource, { error: updateResourceError }] = useMutation(mutations.resource.updateResource);
  const [updateLocation] = useMutation(mutations.locations.updateLocation);

  const handleSubmit = async (
    values: Resource,
    skipSubmit: boolean = false
  ) => {
    let res;
    let data: any;
    let response;

    const { file, document, logoDescription, ...rest } = values;
    const files = [];
    const token = await getTokenAsync();
    const headers = {
      "Authorization": token ? `Bearer ${token}` : null
    }

    if (!skipSubmit) {
      setStatus(statusConstants.SUBMITTING)
      setLoading(true);
      const req = {
        variables: {
          ...clean(rest),
          effectiveOn: new Date(values.effectiveOn),
          companyId: "2da6b4d5-1ca6-4b0a-9696-09f50d99ada4"
        },
      };

      try {

        if (values.id) {
          setStatus(statusConstants.SUBMITTING)
          res = await updateResource(req);

          if (res?.data?.updateResource?.resource) {

            data = res.data.updateResource.resource;

            if (data.locations[0].effectiveOn !== data.effectiveOn || data.locations[0].expiredOn !== data.expiredOn) {
              const mainLocationData = await updateLocation({
                variables: clean({
                  ...data.locations[0],
                  type: "Main",
                  effectiveOn: data.effectiveOn,
                  expiredOn: data.expiredOn
                })
              })

              if (mainLocationData?.data?.updateLocation?.location) {
                data.locations[0] = mainLocationData.data.updateLocation.location as Location;
              }
            }
          }

          const newNodes = normalizedTableData.map((item) => {
            if (item.id === values.id) {
              return data;
            } 
            
            return item;

          });

          setNormalizedTableData(newNodes)

        } else {

          res = await createResource(req);
          if (res?.data?.createResource?.resource) {
            data = res.data.createResource.resource;
            const locations = await createLocation({
              variables: clean({
                effectiveOn: data.effectiveOn,
                expiredOn: data.expiredOn || "",
                resourceId: data.id,
                companyId: req.variables.companyId,
                name: "Main",
                country: "USA",
                type: "Main",
                serviceRangeType: "Nation"
              })
            });

            if (locations?.data?.createLocation?.location) {
              data.locations = [{ ...locations.data.createLocation.location, effectiveOn: new Date(locations.data.createLocation.location.effectiveOn) }];
            }

          }

          setNormalizedTableData([data, ...normalizedTableData]);

        }
      } catch (err) {
        // setStatus(statusConstants.IDLE);
        setStatus(statusConstants.IDLE);
        setLoading(false);
        return;
      }

      if (Boolean(file && data.id && file.dirty)) {

        const formData = new FormData();
        formData.append("file", file?.file);
        //@ts-ignore
        formData.append("companyId", rest.companyId)
        formData.append("objectId", data.id);
        formData.append("category", "Resource.Logo");
        if (logoDescription) {
          formData.append("description", logoDescription);
        }
        await submitFile(formData, headers);
        files.push(file);
        data.file = file;

      } else if (Boolean(file && data.id && !file.dirty && Boolean(logoDescription && file.description !== logoDescription))) {

        const id = req.variables.files.find((item: any) => item.category === "Resource.Logo").id
        await updateFile(id, logoDescription, headers);
        files.push({
          ...file,
          description: logoDescription
        });
        data.file = {
          ...file,
          description: logoDescription
        };

      }
      else if (!file && req.variables.files?.find((item: any) => item.category === "Resource.Logo")) {

        const id = req.variables.files.find((item: any) => item.category === "Resource.Logo").id
        await deleteFile(id, headers)
        data.file = null;

      } else {

        if (file) {
          files.push(file);
          data.file = file;
        }

      }

      if (document && data.id && document.dirty) {

        const formData = new FormData();
        formData.append("file", document.file);
        formData.append("companyId", rest.companyId?.toString() ?? "")
        formData.append("objectId", data.id);
        formData.append("category", "Resource");

        await submitFile(formData, headers);
        files.push(document)

      } else if (!document && req.variables.files?.find((item: any) => item.category === "Resource")) {

        const id = req.variables.files.find((item: any) => item.category === "Resource").id
        await deleteFile(id, headers)
        data.document = null;

      } else {

        if (document) {
          files.push(document);
        }

      }

      if (data) {

        const nextData = {
          ...req.variables,
          ...data,
          effectiveOn: parseDate(values.effectiveOn),
          logoDescription: values.logoDescription,
          files,
          tags: data?.tags ?? [],
          providerTags: data?.providerTags ?? []
        }

        client.writeQuery({
          query: query.resource.GET_RESOURCE,
          variables: {
            id: nextData.id
          },
          data: {
            resourceById: nextData
          }
        });

        response = nextData;
        setCurrentId(nextData.id);
      };

      setLoading(false)
    };

    setStatus(statusConstants.SUCCESS, "Successfully saved Resource")
    return response;
    // setActiveStep(1);
  };

  return [handleSubmit, loading] as const;
};
