import React from 'react';

import { alpha, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { 
  useSearch
} from "~/features/search/SearchProvider"

const useStyles = makeStyles(theme => ({
  search: {
    width: '100%',
    margin: '0px 0px ',
    position: 'relative',
    display: 'flex', 
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

const GlobalFilter = () => {
  const classes = useStyles();

  const {
    searchTerm, 
    handleSearch,
    loading
  } = useSearch();

  return (
    <div className={classes.search}>
      <TextField
        fullWidth
        shrink="true"
        variant="outlined"
        label="Search"
        value={searchTerm}
        onChange={e => {
          handleSearch(e.target.value || "");
        }}
        InputProps={{
          startAdornment: loading ?  <InputAdornment position="start">
          <CircularProgress size={24} disableShrink />
          </InputAdornment> : (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default GlobalFilter;
