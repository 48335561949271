import { createPrice } from "./createPrice";
import { deletePrice } from "./deletePrice";

const prices = {
    createPrice,
    deletePrice
}

export {
    prices
}