import { gql } from '~/__generated__';

export const GET_RESOURCE = gql(/* GraphQL */`
    query ResourceById($id: UUID!) {
        resourceById(id: $id) {
            id
            name
            type
            description
            effectiveOn
            expiredOn
            archivedOn
            tags
            providerTags
            files {
                id
                fileName
                category
                description
            }
            categories {
                id
                name
            }
            locations {
                name
                id
                serviceRangeType
                effectiveOn
                expiredOn
                email
                website
                phoneNumber
                helpLine
                city
                state
                street2
                street
                postalCode
                country
                deletedOn
                type
                offers {
                    id
                    name
                    description
                    instructions
                    uri
                    effectiveOn
                    expiredOn
                    deletedOn
                }

                contacts {
                    id
                    title
                    name
                    email
                    phoneNumber
                    effectiveOn
                    expiredOn
                    deletedOn
                }

                products {
                    id
                    name
                    description
                    effectiveOn
                    expiredOn
                    prices {
                        id
                        description
                        unitPrice
                        unit
                        effectiveOn
                        expiredOn
                    }
                }
            }

        }
    }
`);
