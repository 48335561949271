import React from "react";
import { Form, Field, FormSpy } from "react-final-form";
import { IDField } from "~/components/library/InputField";
import { FormApi } from "final-form"
import arrayMutators from "final-form-arrays";
import Grid from "@material-ui/core/Grid";
import { LocationFormProps, Location } from "./types";
import { basicFormfields } from "./fields";
import { DeleteButton } from "./DeleteButton";
import { useStatus, statusConstants } from "~/features/status";
import { IconButton, Tooltip } from "@material-ui/core";
import { Link } from "@material-ui/icons";

export const LocationForm = (props: LocationFormProps<Location>) => {
  const {
    onSubmit,
    formRef,
    initialValues,
    selected,
    exposeValues,
    destroyAddNew,
    resource,
    handleFormPassesValidation,
    handleSelectRecord
  } = props;
  const { setStatus, status } = useStatus();

  const handleSubmit = async (values: any, form: FormApi) => {
    if (form.getState().dirty) {
      await onSubmit({
        ...values,
        dirty: true
      })
    } else await onSubmit(values);
    form.reset();
  }

  return (
    <Form
      key={`${selected}-location-form`}
      onSubmit={(values: any, form: FormApi) => handleSubmit(values, form)}
      initialValuesEqual={(a, b) => JSON.stringify(a) === JSON.stringify(b)}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={initialValues}
      render={(props) => {
        const { handleSubmit } = props;
        const websiteValue = props.form.getFieldState("website")?.value
        const websiteValid = props.form.getFieldState("website")?.valid;
        return (
          <form onSubmit={handleSubmit} ref={formRef}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {
                    initialValues?.id ? <Grid item lg={8} key="location">
                      {/* @ts-ignore */}
                      <Field name="id" label="Id" InputProps={{ readOnly: true }} fullWidth component={IDField} />
                    </Grid> : ""
                  }
                  {basicFormfields.map((field, index) => {

                    if (field.name === "website") {
                      return <Grid item key={field.name} {...field.wrapperProps} style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ width: "80%" }}>
                          {/* @ts-ignore */}
                          <Field {...field} />
                        </div>
                        <Tooltip title="Link to Website">
                          {/* @ts-ignore */}
                          <IconButton disabled={!websiteValid} onClick={() => { if (websiteValid) { window.open(websiteValue, "_blank") } }} style={{ marginTop: "16px", marginLeft: "8px" }}>
                            <Link></Link>
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    }

                    if (initialValues?.type === "Main" && field.name === "effectiveOn") {
                      return <Grid item key={field.name} {...field.wrapperProps}>
                        {/* @ts-ignore */}
                        <Field {...field} InputProps={{ readOnly: true }} />
                      </Grid>
                    };

                    if (initialValues?.type === "Main" && field.name === "expiredOn") {
                      return <Grid item key={field.name} {...field.wrapperProps}>
                        {/* @ts-ignore */}
                        <Field {...field} initialValue={resource.expiredOn} InputProps={{ readOnly: true }} />
                      </Grid>
                    }

                    return <Grid item key={field.name} {...field.wrapperProps}>
                      {/* @ts-ignore */}
                      <Field {...field} />
                    </Grid>
                  })}

                  <FormSpy
                    subscription={{
                      values: true,
                      valid: true,
                      hasValidationErrors: true,
                      errors: true,
                      pristine: true,
                      dirtySinceLastSubmit: true,
                      dirty: true
                    }}
                    onChange={(state) => {
                      const { values, valid, dirty, pristine } = state;
                      if (pristine && !initialValues?.id) {
                        handleFormPassesValidation(true);
                        return;
                      }
                      if (pristine && valid && initialValues?.id) {
                        if (status !== statusConstants.IDLE || status !== statusConstants.SUCCESS) {
                          setTimeout(() => setStatus(statusConstants.IDLE), 0)
                        }
                      };


                      if (dirty) {
                        if (valid) {
                          handleFormPassesValidation(true)
                          if (!values.id) {
                            setStatus(statusConstants.ACTIVE, statusConstants.PLEASE_SAVE_MESSAGE);
                          } else {
                            setStatus(statusConstants.ACTIVE, statusConstants.PLEASE_SAVE_MESSAGE);
                          }
                        } else {
                          handleFormPassesValidation(false)
                          setStatus(statusConstants.ACTIVE)
                        };
                      } else {
                        setStatus(statusConstants.IDLE)
                      }

                      setTimeout(() => exposeValues(values, valid), 0) // https://github.com/final-form/react-final-form/issues/809
                    }}
                  />
                </Grid>
              </Grid>
              {resource?.locations && resource.locations[selected]?.type !== "Main" ? <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                <DeleteButton id={initialValues?.id} resourceId={resource?.id} destroyAddNew={destroyAddNew} handleSelectRecord={handleSelectRecord}/>
              </Grid> : ""}
            </Grid>
          </form>
        );
      }}
    />
  );
};
