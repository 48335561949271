import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core';
import { Box, LinearProgress } from '@material-ui/core';

import { Button as MuiButton } from "@material-ui/core";
import { ButtonProps as MuiButtonProps } from "@material-ui/core/Button";
import { useStatus, statusConstants } from "~/features/status";

export interface ButtonProps
  extends MuiButtonProps {
    submitting?: boolean;
    disableProgress?: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    // alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    width: 'calc(100% - 10px)',
    position: 'absolute',
    top: '88%',
    left: 5,
  },
}));

const ProgressBar = withStyles({
  root: {
    height: 3,
    // Not support by the current version of material UI.
    // backgroundColor: darken('#fff', 0.2),

    borderRadius: 20,
  },
  bar: {
    borderRadius: 20,
    // Not support by the current version of material UI.
    // backgroundColor: darken('#fff', 0.05),
  },
})(LinearProgress);

export const Button = (props: ButtonProps) => {
  const {
    children,
    submitting,
    disableProgress = false,
    ...rest
  } = props;
  const classes = useStyles();
  const { isLoading, status } = useStatus();
  const isSubmitting = status === statusConstants.SUBMITTING;
  return (
    <Box className={classes.root}>
      <MuiButton
        {...rest}
        variant="contained"
        size="large"
        disabled={submitting || props.disabled || isLoading}
        disableElevation
      >
        {children}
      </MuiButton>
      <Box className={classes.buttonProgress}>
        {!disableProgress && ((submitting || isSubmitting) && <ProgressBar />)}
      </Box>
    </Box>
  );
};
