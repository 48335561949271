import { InputField } from "../../../library/InputField/InputField";
import { FieldProp } from "../../form";
import { parseDate, normalizeDate, formatToPhone } from "../../utils"
import { required, isValidPhone, validateEmail, composeValidators, effectiveOnLessThanExpiry, expiryAfterEffectiveOn, maxLength } from "../../validation";

const maxLength255 = maxLength(255, '{{length}}/{{max}}');
const contactFields: Array<FieldProp> = [
  {
    name: "name",
    label: "Contact Name",
    validate: composeValidators(required, maxLength255),
    required: true,
    component: InputField,
    fullWidth: true,
    // item: true,
    wrapperProps: {
      xs: 4,
    }
  },
  {
    name: "title",
    label: "Contact Title",
    validate: maxLength255,
    component: InputField,
    fullWidth: true,
    // item: true,
    wrapperProps: {
      xs: 4,
    }
  },
  {
    name: "phoneNumber",
    label: "Contact Phone",
    validate: isValidPhone,
    component: InputField,
    format: (value) => formatToPhone(value),
    fullWidth: true,
    // item: true,
    wrapperProps: {
      xs: 4,
    }
  },
  {
    name: "email",
    label: "Contact Email",
    validate: validateEmail,
    component: InputField,
    fullWidth: true,
    // item: true,
    wrapperProps: {
      xs: 6,
    }
  },

  {
    name: "effectiveOn",
    label: "Effective",
    validate: composeValidators(required, effectiveOnLessThanExpiry),
    component: InputField,
    parse: (value) => normalizeDate(value),
    format: (value) => parseDate(value),
    fullWidth: true,
    required: true,
    // item: true,
    type: "date",
    wrapperProps: {
      xs: 3,
    },
  },
  {
    name: "expiredOn",
    label: "End",
    validate: expiryAfterEffectiveOn,
    component: InputField,
    parse: (value) => normalizeDate(value),
    format: (value) => parseDate(value),
    fullWidth: true,
    // item: true,
    type: "date",
    wrapperProps: {
      xs: 3,
    },
  },
];

export { contactFields };