
import {
    createMuiTheme,
    responsiveFontSizes,
  } from "@material-ui/core/styles";

let theme = createMuiTheme({
    palette: {
      primary: {
        light: "#F2F9FF",
        main: "#006AB0",
        dark: "#00406A",
      },
      red: {
        light: "#FBDCDE",
        main: "#ED5158",
      },
      secondary: {
        light: "#F0F5E9",
        main: "#97BA52",
        dark: "#5D7332",
      },
      error: {
        light: "#FAEDED",
        main: "#BF1F1F",
        dark: "#731212",
      },
      warning: {
        light: "#FAF7ED",
        main: "#E6BC05",
        dark: "#73600E",
      },
    },
    typography: {
      useNextVariants: true,
    },
    overrides: {
      MuiFormControl: {
        marginDense: {
          marginTop: 0,
          // minHeight: 66
        },
      },
      MuiOutlinedInput: {
        marginDense: {
          paddingTop: 4,
          paddingBottom: 4,
          backgroundColor: "#f8f8f8"
        },
      },
      MuiAutocomplete: {
        input: {  
          paddingTop: "10.5px !important",
          paddingBottom: "10.5px !important"
        },
        inputRoot: {
          padding: "4px !important"
        }
      },

      MuiAccordionSummary: {
        content: {
          marginTop: "0px !important"
        }
      }
    },
  });
  
  theme = responsiveFontSizes(theme);
  export { theme }