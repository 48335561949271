import { gql } from "graphql-tag";

const updateOffer = gql`
    mutation updateOffer(
            $locationId: UUID!
            $id: UUID!,
            $name: String!,
            $description: String,
            $instructions: String!,
            $uri: String,
            $effectiveOn: DateTime!,
            $expiredOn: DateTime,

    ) {
        updateOffer(
            input: {
                id: $id
                locationId: $locationId
                name: $name
                description: $description
                instructions: $instructions
                uri: $uri
                effectiveOn: $effectiveOn
                expiredOn: $expiredOn
            }
        ) {
        offer 
            {
                id
                name
                description
                instructions
                uri
                effectiveOn
                expiredOn
            }
        }
    }
`;

export {
    updateOffer
}