import React from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import { BrowserRouter as Router } from "react-router-dom";
import { AppBar } from "./library/AppBar";
import { theme } from "./theme";
import RenderRoutes from "./pages/routes";
import {
  UnauthenticatedTemplate,
  AuthenticatedTemplate,
} from "@azure/msal-react";
import AuthorizationPage from "./pages/authentication";
import { NodesProvider } from "~/features/nodes/NodesProvider";
import { PersistingTableStateProvider } from "~/features/persistingTableState";

const Root = () => (
  <PersistingTableStateProvider>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Box className="min-h-screen bg-gray-300 pb-64">
          <AuthenticatedTemplate>
            <NodesProvider>
              <AppBar/>
              <RenderRoutes />
            </NodesProvider>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <AuthorizationPage />
          </UnauthenticatedTemplate>
        </Box>
      </Router>
    </MuiThemeProvider>
  </PersistingTableStateProvider>
);

export { theme };
export default Root;
