import { gql } from "graphql-tag";

const deletePrice = gql`
    mutation deletePrice(
            $id: UUID!
    ) {
        deletePrice(
            input: {
                id: $id
            }
        ) {
        price 
            {
                id
            }
        }
    }
`;

export {
    deletePrice
}