import { gql } from "graphql-tag";

const createLocation = gql`
    mutation createLocation(
        $name: String!,
        $resourceId: UUID!,
        $email: String,
        $phoneNumber: String,
        $website: String,
        $helpLine: String,
        $serviceRangeType: String,
        $type: String!,
        $effectiveOn: DateTime!,
        $expiredOn: DateTime,
        $street: String
        $street2: String
        $city: String
        $state: String
        $postalCode: String
        $country: String!
    ) {
        createLocation(
            input: {
                companyId: "2DA6B4D5-1CA6-4B0A-9696-09F50D99ADA4"
                resourceId: $resourceId
                name: $name
                email: $email
                phoneNumber: $phoneNumber
                website: $website
                helpLine: $helpLine
                serviceRangeType: $serviceRangeType
                type: $type
                effectiveOn: $effectiveOn
                expiredOn: $expiredOn
                street: $street
                street2: $street2
                city: $city
                state: $state
                postalCode: $postalCode
                country: $country
            }
        ) {
            location 
            {
                id
                name
                email
                website
                helpLine
                type
                serviceRangeType
                street
                street2
                city
                state
                postalCode
                country
                effectiveOn
            }
        }
    }
`;

export {
    createLocation
}