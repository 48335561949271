import { useEffect, useState } from "react";
import { Location, LocationFormProps } from "../types";
import { useStatus, statusConstants } from "~/features/status";
import { normalizeDate } from "~/components/forms/utils";

const useRecords = (props: LocationFormProps) => {
    const {
        handleFormPassesValidation,
        initialValues,
        resource,
        passedValidation
    } = props;
    const [selected, setSelected] = useState<number>(0);
    const { setStatus, status } = useStatus();

    const createEmpty = (length: number) => {
        const dateProps = {
            effectiveOn: typeof resource.effectiveOn == "string" ? normalizeDate(resource.effectiveOn) : resource.effectiveOn,
            expiredOn: resource?.expiredOn ? normalizeDate(resource.expiredOn) : null,
            country: "USA",
        }
        return {
            ...dateProps,
            name: length ? `` : "Main",
            type: length ? "Other" : "Main",
            serviceRangeType: "Nation"
        } as Location
    };

    const initializeRecord = () => initialValues?.length
        ? [
            ...initialValues.filter((location: Location) => !location.deletedOn)
        ]
        : [
            createEmpty(0)
        ]

    const [records, setRecords] = useState<Location[]>(initializeRecord());

    const [activeStep, setActiveStep] = useState<number>(0);
    const handleSetActiveStep = (step: number) => {
        setActiveStep(step);
        if (status === statusConstants.ACTIVE) {
            setStatus(statusConstants.IDLE)
        }
    };

    const [currRecord, setCurrRecord] = useState<Location | null>(
        initialValues?.length ? initialValues[0] : null
    );

    const destroyAddNew = () => {
        if (records[0].id) {
            setSelected(0)
            setCurrRecord(records[0]);
            handleFormPassesValidation(true);
            const newRecords: Location[] = [];
            records.forEach((item: Location, index: number) => {
                if (index !== records.length - 1) {
                    newRecords.push(item);
                }
            })
            setRecords(newRecords);
        }
    }

    const exposeValues = (values: Location, valid: boolean, name: string) => {
        handleFormPassesValidation(valid);
        if (name) {
            setCurrRecord({
                ...currRecord, [name]: {
                    ...values
                }
            } as Location);
            return;
        }
        if (valid || !activeStep) setCurrRecord(values);
        return;
    };

    useEffect(() => {
        if (!resource?.locations?.length) {
        } else setRecords([...resource.locations.filter((location: Location) => !location.deletedOn)])
    }, [resource])

    useEffect(() => {
        if (status === statusConstants.IDLE && !passedValidation) {
            handleFormPassesValidation(true);
        }
    }, [status])

    return {
        records,
        activeStep,
        handleSetActiveStep,
        currRecord, setCurrRecord,
        selected, setSelected,
        destroyAddNew,
        exposeValues,
        createEmpty,
        setRecords
    }
}

export {
    useRecords
}