import React from "react";
import { Grid } from "@material-ui/core";
import { LocationTabs } from "~/components/library/Tabs/LocationTabs";
import { useForms } from "./useForms";
import { TabPanel } from "./TabPanel";
import {
  IFormTemplate,
  Forms
} from "./types";

export const FormTemplate = (props: IFormTemplate) => {
  const { handleSetActiveStep, activeStep, formProps, selected } = props;
  const { records } = formProps;
  const forms = useForms(formProps);
  return (
    <Grid item xs={10} style={{ padding: 0 }}>
      <LocationTabs
        locationExists={Boolean(records && records[selected]?.id)}
        handleSetActiveStep={handleSetActiveStep}
        activeStep={activeStep}
        passedValidation={formProps.passedValidation}
      />
      {forms.map((form: Forms, index: number) => (
        <TabPanel
          value={activeStep}
          key={`${form.value}-${form.name}-tab-panel`}
          index={form.value}
        >
          <div>
            {form.component}
          </div>
        </TabPanel>
      ))}
    </Grid>
  );
};
