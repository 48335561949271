import { InputField } from "~/components/library/InputField/InputField";
import { SelectField } from "~/components/library/SelectField/SelectField";
import { StatesField } from "~/components/library/SelectField/StatesField";
import CountryField from "~/components/library/SelectField/CountryField";
import { required, isValidPhone, validateEmail, composeValidators, isValidURL, effectiveOnLessThanExpiry, expiryAfterEffectiveOn, isValidZipCode } from "../../validation";
import { FieldProp } from "../../form";
import { parseDate, normalizeDate, formatToPhone } from "../../utils";


const basicFormfields: Array<FieldProp> = [
  {
    name: "name",
    label: "Location Name",
    validate: required,
    required: true,
    component: InputField,
    fullWidth: true,
    // item: true,
    wrapperProps: {
      xs: 12,
      sm: 7,
      md: 8,
      lg: 8,
    },
  },
  {
    name: "serviceRangeType",
    label: "Location Geotype",
    component: SelectField,
    options: [
      { value: "City" },
      { value: "County" },
      { value: "State" },
      { value: 'Regional' },
      { value: "National" },
      { value: "Global" },
      { value: "N/A" },
    ],
    fullWidth: true,
    // item: true,
    wrapperProps: {
      xs: 12,
      sm: 5,
      md: 4,
      lg: 4,
    },
  },
  {
    name: "effectiveOn",
    label: "Effective",
    validate: composeValidators(required, effectiveOnLessThanExpiry),
    component: InputField,
    parse: (value) => normalizeDate(value),
    format: (value) => parseDate(value),
    fullWidth: true,
    required: true,
    // item: true,
    type: "date",
    wrapperProps: {
      xs: 6,
      sm: 6,
      md: 4,
      lg: 4
    },
  },
  {
    name: "expiredOn",
    label: "End",
    validate: expiryAfterEffectiveOn,
    component: InputField,
    parse: (value) => normalizeDate(value),
    format: (value) => parseDate(value),
    fullWidth: true,
    type: "date",
    wrapperProps: {
      xs: 6,
      sm: 6,
      md: 4,
      lg: 4
    },
  },
  {
    name: "website",
    label: "Location Website",
    component: InputField,
    validate: isValidURL,
    fullWidth: true,
    // item: true,
    wrapperProps: {
      xs: 12,
    },
  },
  {
    name: "phoneNumber",
    label: "Location Phone",
    component: InputField,
    validate: isValidPhone,
    fullWidth: true,
    // item: true,
    format: (value) => formatToPhone(value),
    wrapperProps: {
      xs: 4,
      sm: 4,
      md: 3,
    },
  },
  {
    name: "helpLine",
    label: "Helpline",
    component: InputField,
    validate: isValidPhone,
    fullWidth: true,
    format: (value) => formatToPhone(value),
    // item: true,
    wrapperProps: {
      xs: 4,
      sm: 4,
      md: 3,
    },
  },
  {
    name: "email",
    label: "Email",
    component: InputField,
    validate: validateEmail,
    fullWidth: true,
    // item: true,
    wrapperProps: {
      xs: 4,
      sm: 4,
      md: 6,
    },
  },


  {
    name: "street",
    label: "Street Address 1",
    component: InputField,
    fullWidth: true,
    // item: true,
    wrapperProps: {
      xs: 12,
      sm: 12,
      md: 6,
    },
  },
  {
    name: "street2",
    label: "Street Address 2",
    component: InputField,
    fullWidth: true,
    // item: true,
    wrapperProps: {
      xs: 12,
      sm: 12,
      md: 6,
    },
  },
  {
    name: "city",
    label: "City",
    component: InputField,
    fullWidth: true,
    // item: true,
    wrapperProps: {
      xs: 3,
    },
  },
  {
    name: "state",
    label: "State",
    component: StatesField,
    fullWidth: true,
    // item: true,
    wrapperProps: {
      xs: 3,
    },
  },
  {
    name: "postalCode",
    label: "Zip",
    component: InputField,
    fullWidth: true,
    validate: isValidZipCode,
    type: "number",
    // item: true,
    wrapperProps: {
      xs: 3,
    },
  },
  {
    name: "country",
    label: "Country",
    component: CountryField,
    required: true,
    validate: required,
    fullWidth: true,
    wrapperProps: {
      xs: 3,
    },
  },
];



export { basicFormfields };
