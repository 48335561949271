import { FieldValidator } from "final-form";
import { isBefore } from "date-fns";

export type ValidatorType = FieldValidator<any>;
interface Record {
  effectiveOn: Date | string;
  expiredOn: Date | string;
};

export const maxLength = (length: number, message: string) => (value: string | null | undefined) =>
  value && value.length > length
    ? message
          .replace('{{max}}', length.toString())
          .replace('{{length}}', value.length.toString())
    : undefined;

export const minValue1 = (value: string | null | undefined) =>
    value && parseInt(value) >= 1
        ? undefined
        : "Price must be at least $1"

export const minValue0 = (value: string | null | undefined) =>
    value?.toString()?.trim() === ""
        ? "required"
        : parseInt(value!) >= 0
            ? undefined
            : "Price cannot be negative"
    
export const fieldArrayRequired: FieldValidator<any[]> = value => value?.length ? undefined : "Required";
export const required: ValidatorType = value => (value ? undefined : 'Required')
export const mustBeNumber: ValidatorType = value => (isNaN(parseInt(value)) ? 'Must be a number' : undefined)
export const minValue: ValidatorType = min => (value: any) =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`
  
  export const validateEmail: ValidatorType = (email) => {
    if (!email) return undefined;
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email && re.test(String(email).toLowerCase()) ? undefined : "Invalid Email Address";
}

  export const isValidPhone: ValidatorType = (email) => {
    if (!email) return undefined;
    const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return email && re.test(String(email).toLowerCase()) ? undefined : "Invalid Number";
}

export const isValidURL: ValidatorType = (url) => {
  if (!url) return undefined;
  let res = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
  return res.test(url) ? undefined : "Invalid URL"
};

export const effectiveOnLessThanExpiry: ValidatorType = (value, allValues, meta) => {
  const formValues = allValues as Record;
  if (!formValues) return undefined;
  if (!value) return undefined;
  let _expiredOn;

  // TODO -- This needs to be refactored for typescript and documentation for what exactly this
  //      -- algorithm is supposed to be doing. To get this to compile, @ts-ignore but don't like it.
  if (meta && meta.name.split(".").length > 1) {
    if (meta.name.split(".").length === 2) {
      const names = meta.name.split(".")[0].split("[");
      // @ts-ignore
      _expiredOn = formValues[names[0]][names[1][0]].expiredOn
    } else if (meta.name.split(".").length === 3) {
      const names = meta.name.split(".")[0].split("[");
      const subNames = meta.name.split(".")[1].split("[");
      // @ts-ignore
      _expiredOn = formValues[names[0]][names[1][0]][subNames[0]][subNames[1][0]].expiredOn
    }
  } else {
    _expiredOn = formValues?.expiredOn
  };

  if (!_expiredOn) {
    return undefined;
  }
  if (meta?.initial && !meta?.dirty) {
    return undefined;
  }
  const expiredOn = typeof _expiredOn === "string" ? new Date(_expiredOn ) : _expiredOn;
  const effectiveOn = typeof value === "string" ? new Date(value) : value;
  return expiredOn && isBefore(effectiveOn, expiredOn) ? undefined : `Effective On date must come before Expired On date`
}

export const expiryAfterEffectiveOn: ValidatorType = (value, allValues, meta) => {
  const formValues = allValues as Record;
  if (!formValues) return undefined;
  if (!value) return undefined;
  let _effectiveOn;
  if (meta && meta.name.split(".").length > 1) {

    // TODO -- This needs to be refactored for typescript and documentation for what exactly this
    //      -- algorithm is supposed to be doing. To get this to compile, @ts-ignore but don't like it.
    if (meta.name.split(".").length === 2) {
      // @ts-ignore
      const names = meta.name.split(".")[0].split("[");
      // @ts-ignore
      _effectiveOn = formValues[names[0]][names[1][0]].effectiveOn
    } else if (meta.name.split(".").length === 3) {
      // @ts-ignore
      const names = meta.name.split(".")[0].split("[");
      // @ts-ignore
      const subNames = meta.name.split(".")[1].split("[");
      // @ts-ignore
      _effectiveOn = formValues[names[0]][names[1][0]][subNames[0]][subNames[1][0]].effectiveOn
    }
  } else {
    _effectiveOn = formValues?.effectiveOn
  };

  if (!_effectiveOn) {
    return undefined;
  }
  if (meta?.initial && !meta?.dirty) {
    return undefined;
  }

  const effectiveOn = typeof _effectiveOn === "string" ? new Date(_effectiveOn ) : _effectiveOn;
  const expiredOn = typeof value === "string" ? new Date(value) : value;

  return effectiveOn && isBefore(effectiveOn, expiredOn) ? undefined : `Effective On date must come before Expired On date`
}

export const isValidZipCode = (zip: string | null | undefined) => {
  if (!zip) {return undefined};
  return /(^\d{5})(-\d{4})?$/.test(zip) ? undefined : "Invalid Zipcode"
};
export const composeValidators = (...validators: ValidatorType[]) => (value: any, allValues: any, meta: any) =>
  validators.reduce((error, validator) => error || validator(value, allValues, meta), undefined)

export const imageValidator: ValidatorType = (value) => {
  if (!value?.file) {
    return undefined;
  } else if (value.width != 380) {
  return `Image width must be less than 380px`
  } else if (value.height != 180) {
    return "Image width must be less than 180px"
  } else return undefined;
}
