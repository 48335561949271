import { gql } from "graphql-tag";

const deleteResource = gql`
  mutation deleteResource($id: UUID!) {
    deleteResource(input: { id: $id }) {
      resource {
        id
      }
    }
  }
`;

export {
  deleteResource
}