import { Location, Basic, Contacts, Product, Price, Offers } from "../../forms/LocationForm/types";
import { Resource } from "~/components/forms/ResourceForm/types"

const BASIC = "basic";
const CONTACTS = "contacts";
const PRODUCTS = "products";
const OFFERS = "offers";

const CREATE = "create";
const EDIT = "edit";


const steps: { [key: number]: keyof Location | string } = {
    0: BASIC,
    1: CONTACTS,
    2: PRODUCTS,
    3: OFFERS,
  };
  

interface LocationFormProps {
  initialValues?: Location[];
  formRef: any;
  passedValidation: boolean;
  handleFormPassesValidation: (passedValidation: boolean) => void;
  type?: string;
  resource: Resource;
}

interface IRecordValues {
  values: Basic | Contacts | Product | Price | Offers;
}

export type {
    IRecordValues,
    LocationFormProps,
    Location
}

const constants = {
  BASIC,
  CONTACTS,
  PRODUCTS,
  OFFERS,
  CREATE,
  EDIT
}

export {
    steps,
    constants
}

