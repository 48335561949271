import React from "react";
import { Grid, Typography, Divider } from "@material-ui/core";
import { HeaderMessage } from "./HeaderMessage";

export interface HeaderProps {
  title: string;
  subtitle?: string;
  children?: string | React.ReactNode;
  disableDivider?: boolean;
  hasControl?: boolean;
}

export const Header = (props: HeaderProps) => {
  const { title, subtitle, children, disableDivider = false } = props;
  return (
    <Grid item xs={12} style={{ paddingBottom: 0 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="h6" className="text-lg font-medium">
            {title}
          </Typography>
          {subtitle ? (
            <Typography variant="body1" color="primary">
              {subtitle}
            </Typography>
          ) : (
            ""
          )}
        </Grid>
        {children ? children : ""}
        {disableDivider ? "" : <Grid item xs={12}>
          <Divider />
        </Grid>}
        <HeaderMessage />
      </Grid>
    </Grid>
  );
};
