import {ColumnDef, createColumnHelper, type RowData} from "@tanstack/react-table";
import type {Resource as GqlQueryResource} from "~/__generated__/graphql";
import MuiCheckbox from "@material-ui/core/Checkbox";
import {dateTimeRender} from "~/library/date";
import {cx} from "~/library/css/cx";
import React from "react";

declare module '@tanstack/react-table' {
    interface ColumnMeta<TData extends RowData, TValue> {
        classes: [string]
    }
}

const columnHelper = createColumnHelper<GqlQueryResource>();

const columns = [
    columnHelper.display({
        id: 'select',
        header: ({table}) => (
            <MuiCheckbox
                aria-label="Select all"
                checked={table.getIsAllPageRowsSelected() ||
                    (table.getIsSomePageRowsSelected())}
                onChange={(e) => table.toggleAllPageRowsSelected(!!e.currentTarget?.checked)}
            />
        ),
        cell: ({row}) => (
            <MuiCheckbox
                aria-label="Select row"
                checked={row.getIsSelected()}
                onChange={(e) => row.toggleSelected(!!e.currentTarget?.checked)}
            />
        ),
        enableSorting: false,
        enableHiding: false,
        enableGrouping: false,
        enableResizing: false,
    }),
    columnHelper.accessor(row => row.name, {
        id: 'name',
        header: 'Resource Name',
        cell: data => data.getValue(),
        enableSorting: true,
        sortingFn: 'alphanumeric',
        meta: {
            classes: ['w-1/2']
        }
    }),
    columnHelper.accessor(row => row.type, {
        id: 'type',
        header: 'Resource Type',
        cell: data => data.getValue(),
        enableSorting: true,
        sortingFn: 'alphanumeric',
        meta: {
            classes: ['w-1/4']
        }
    }),
    columnHelper.accessor(row => row.effectiveOn, {
        id: 'effectiveOn',
        header: 'Effective Date',
        cell: data =>
            dateTimeRender(data.getValue(), {dateOnly: true, defaultDateFormat: 'MMM d, yyyy'}),
        enableSorting: true,
        sortingFn: 'datetime',
        meta: {
            classes: ['w-1/6']
        }
    }),
    columnHelper.accessor(row => row.expiredOn,{
        id: 'expiredOn',
        header: () => <span className="text-center">Status</span>,
        cell: ({row, getValue}) => {
            const {expiredOn} = row.original;
            const value = getValue();

            const notExpired = !expiredOn || new Date() < new Date(expiredOn);
            const statusStyles = !value && notExpired
                ? "text-green-800 bg-green-200"
                : "text-yellow-800 bg-yellow-200";
            return (
                <div className={cx(['font-semibold text-sm px-2 py-1 rounded-full text-center', statusStyles])}>
                    {!value && notExpired ? "Active" : "Archived"}
                </div>
            );
        },
        enableSorting: true,
        meta: {
            classes: ['w-1/3']
        }
    }),
    columnHelper.accessor(row => row.locations, {
        id: 'locations',
        header: 'Loc#',
        cell: data => data.getValue().length,
        enableSorting: false,
        meta: {
            classes: ['w-1/6']
        }
    }),
    columnHelper.accessor(row => row.locations, {
        id: 'products',
        header: 'Product#',
        cell: data => data.getValue()
            .reduce((accumulator, location) => accumulator + location.products.length, 0),
        enableSorting: false,
        meta: {
            classes: ['w-1/6']
        }
    }),
    columnHelper.accessor(row => row.locations, {
        header: 'Offer#',
        cell: data => data.getValue()
            .reduce((accumulator, location) => accumulator + location.products.length, 0),
        enableSorting: false,
        meta: {
            classes: ['w-1/6']
        }
    }),
];

export default columns as ColumnDef<GqlQueryResource>[];
