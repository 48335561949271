import React, { useState, useContext, useEffect } from "react";

const IDLE = "idle";
const SUBMITTING = "submitting";
const SUCCESS = "success";
const ERROR = "error";
const LOADING = "loading";
const ACTIVE = "active";

const PLEASE_SAVE_MESSAGE = "You have unsaved changes, please save form before proceeding.";
const PLEASE_SAVE_LOCATION = "You have unsaved Location changes, click Save + Add New to submit the record";

export type StatusContent = {
  status: string
  setStatus:(c: string, m?: string | undefined) => void
  message: string
  isLoading: boolean;
}

const StatusContext = React.createContext<StatusContent>({
  status: IDLE,
  setStatus: () => {},
  message: "",
  isLoading: false
});

const useStatus = () => useContext(StatusContext)

const StatusProvider = ({ children }: { children: React.ReactNode}) => {
  const [status, setStatus] = useState<string>(IDLE);
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
  }, [status]);
  

  const handleSetStatus = (nextStatus: string, nextMessage?: string) => {
    if (nextMessage) {
      setMessage(nextMessage);
    } else {
      setMessage("");
    }

    setStatus(nextStatus);
  };

  const isLoading = Boolean(status === LOADING || status === SUBMITTING);
  const data = { status, setStatus: handleSetStatus, message, isLoading };
  return (
    <StatusContext.Provider value={data}>{children} </StatusContext.Provider>
  );
};

export { useStatus, StatusProvider };
export { IDLE, SUBMITTING, SUCCESS, ERROR, LOADING, ACTIVE, PLEASE_SAVE_MESSAGE, PLEASE_SAVE_LOCATION}
