
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { mutations, query } from "~/features/graphql";
import { Button } from "~/components/library/Button";
import { useStatus, statusConstants } from "~/features/status";
import { Tooltip } from "@material-ui/core";

interface Props {
  id: string | undefined;
  destroyAddNew: () => void;
  resourceId: string | undefined;
  handleSelectRecord: (index: number) => void;
}

export const DeleteButton = ({ id, destroyAddNew, resourceId, handleSelectRecord }: Props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteLocation, { loading, error }] = useMutation(mutations.locations.deleteLocation, {
    refetchQueries: [{
      query: query.resource.GET_RESOURCE,
      variables: { id: resourceId }
  }],
    awaitRefetchQueries: true
  });

  const { setStatus } = useStatus();

  const handleDelete = async (e: any) => {
    e.preventDefault()
    setStatus(statusConstants.LOADING);
    if (id) {
      await deleteLocation({ variables: { id } })
      if (!error) {
        setStatus(statusConstants.SUCCESS, "Record successfully deleted");
        handleSelectRecord(0);
      }
    } else {
      destroyAddNew();
      setStatus(statusConstants.SUCCESS, "Create Location was cancelled");
    }
  }

  const handleCancel = () => setIsDeleting(false);

  if (isDeleting) {
    return <div style={{ display: "flex" }}>
      <ConfirmDeleteButton onDelete={handleDelete} isDeleting={isDeleting}/>
      <CancelDeleteButton onClick={handleCancel} isDeleting={isDeleting} />
    </div>
  }

  

  return <Button submitting={loading} disableProgress={!loading} onClick={() => setIsDeleting(true)}  disableElevation>Delete</Button>
}

const ConfirmDeleteButton = ({ onDelete, isDeleting }: { onDelete: (e: any) => {}, isDeleting: boolean }) => (
  <Tooltip title="Confirm">
    <Button style={{ background: "#34D399", color: "#fff"}}onClick={onDelete}> Confirm Delete </Button>
  </Tooltip>
);

const CancelDeleteButton = ({ onClick, isDeleting }: { onClick: () => void, isDeleting: boolean }) => (
  <Tooltip title="Cancel">
  <Button style={{ background: '#FCD34D', color: "#fff" }} onClick={onClick}> Cancel</Button>
  </Tooltip>
);