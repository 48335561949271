import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useStatus } from "~/features/status";

export interface CheckFieldProps {
  checked: boolean;
  option: Option;
  valueKey: keyof Option;
  onChange: (checked: boolean, option: string) => void;
  index: number;
}

export interface Option {
  name: string;
  value?: any;
  id: string;
}

export const CheckField = (props: CheckFieldProps) => {
  const { option, onChange } = props;
  const { isLoading } = useStatus();
  return (
    <FormControlLabel
    disabled={isLoading}
      control={
        <Checkbox
          onChange={(event) => {
            onChange(event.target.checked, option.id);
          }}
          color="primary"
          checked={props.checked}
        />
      }
      label={option.name}
    />
  );
};
