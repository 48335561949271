import { gql } from '~/__generated__';

const GET_COMPANIES = gql(/* GraphQL */`
    query Companies {
        companies {
            id
            name
        }
    }
`);

export const companies = {
    GET_COMPANIES
}
