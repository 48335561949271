import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { InputField } from "../InputField/InputField";
import { SelectFieldProps } from "./SelectField";

const states = [
  "AL",
  // "AK": "Alaska",
  // "AS": "American Samoa",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  // "FM": "Federated States Of Micronesia",
  "FL",
  "GA",
  // "GU": "Guam",
  // "HI": "Hawaii",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  // "MH": "Marshall Islands",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  // "MP": "Northern Mariana Islands",
  "OH",
  "OK",
  "OR",
  // "PW": "Palau",
  "PA",
  // "PR": "Puerto Rico",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY"
]

export function StatesField(props: SelectFieldProps<string>) {
  return (
    <Autocomplete
      id="StatesField-autocomplete"
      options={states}
      onChange={(event, value) => props.input.onChange(value)}
      autoHighlight
      getOptionLabel={(option) => option}
      defaultValue={states.find((option) => option === props.input.value)}
      renderInput={(params) =>
        <InputField
          disableMemo={true}
          //@ts-ignore
          ref={params.InputProps.ref}
          {...params}
          {...props}
          inputProps={params.inputProps}
        />}
    />
  );
}
