import React from "react";
import Root from "./components";
import "./App.css";
import { MsalProvider, MsalProviderProps } from "@azure/msal-react";
import Apollo from "~/features/apollo";

console.log("dynamic", import.meta.env);

function App({ instance }: MsalProviderProps | any) {
  return (
    <MsalProvider instance={instance}>
      <Apollo>
        <Root />
      </Apollo>
    </MsalProvider>
  );
}

export default App;
